import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
// import { Store as StoreIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { imgAccepted, maxFileImg } from "../config";
//
import UploadImage from "./uploadImage";
// ----------------------------------------------------------------------

UpdateProductForm.propTypes = {
  handleOnSubmit: PropTypes.func,
  product: PropTypes.object,
};

// eslint-disable-next-line complexity
export default function UpdateProductForm({
  handleOnSubmit = () => {},
  onChangeImgMain = () => {},
  onDeleteImg = () => {},
  product,
}) {
  const [state, setState] = useState({
    storeQty: [],
    inventory: {},
    selectStores: [],
    oldFiles: [],
    files: [],
  });

  const { files, oldFiles } = state;

  const EditProductSchema = Yup.object().shape({
    sku: Yup.string().required(`El SKU es requerido`),
    eiPartnerCode: Yup.string().required(`El código de barra es requerido`),
    description: Yup.string().required(`La descripción es requerida`),
    category: Yup.string().required(`La categoria es requerido`),
    extraInfo: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      sku: product.sku,
      eiPartnerCode: product.eiPartnerCode,
      description: product.description,
      category: product.category,
      extraInfo: product.extraInfo,
    },
    validationSchema: EditProductSchema,
    onSubmit: (values) => {
      values.id = product.id;
      handleOnSubmit(values, files);
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (Object.keys(product).length) {
      const oldStores = product.Stores;
      const newInventory = {};
      const newStoreQty = oldStores.map(({ id, ProductStores }) => {
        const { stock } = ProductStores;
        newInventory[id] = stock;
        return id;
      });
      setState((prevState) => ({
        ...prevState,
        storeQty: newStoreQty,
        inventory: newInventory,
        oldFiles: product.images.map(({ url, main, id }) => ({
          file: url,
          main,
          id,
        })),
      }));
    }
  }, [product]);

  const handleOnSelected = ({ file, name, originalFile }) => {
    const currentDate = Math.round(new Date().getTime() / 1000);

    setState((prevState) => {
      const { files } = prevState;
      const nextFiles = files.length ? [...files] : [];
      nextFiles.push({
        file,
        name: `${currentDate}_${name}`,
        originalFile,
        main: !files.length,
      });
      return { ...prevState, files: nextFiles };
    });
  };

  const handleOnDeleted = (index) => {
    setState((prevState) => {
      const { files } = prevState;
      const findFile = files.find((_, key) => index === key);

      const nextFiles = files
        .filter((_, key) => index !== key)
        .map((item, key) => ({
          ...item,
          main: findFile.main ? !key : item.main,
        }));
      return { ...prevState, files: nextFiles };
    });
  };

  const handleMaxSizeFile = (error) => {
    if (error) {
      alert(`El archivo no puede pesar más de ${maxFileImg / 1024 / 1024} MB`);
    }
  };

  const handleOnSelectMainImg = (index) => {
    setState((prevState) => {
      const { files } = prevState;
      const nextFiles = files.map((item, key) => ({
        ...item,
        main: key === index,
      }));

      return { ...prevState, files: nextFiles };
    });
  };

  const handleOnSelectMainImgOld = (index) => {
    setState((prevState) => {
      const { oldFiles } = prevState;
      const nextFiles = oldFiles.map((item, key) => ({
        ...item,
        main: key === index,
      }));
      const { id: imageId } = oldFiles.find((_, key) => key === index);
      onChangeImgMain(imageId);

      return { ...prevState, oldFiles: nextFiles };
    });
  };

  const handleOnDeleteImgOld = (index) => {
    const { id } = oldFiles.find((_, key) => index === key);
    onDeleteImg(id);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack pt={2}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} md={6}>
              <TextField
                disabled={true}
                fullWidth
                type="text"
                label="SKU"
                {...getFieldProps(`sku`)}
                error={Boolean(touched.sku && errors.sku)}
                helperText={touched.sku && errors.sku}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={true}
                fullWidth
                type="text"
                label="Código de barra"
                {...getFieldProps(`eiPartnerCode`)}
                error={Boolean(touched.eiPartnerCode && errors.eiPartnerCode)}
                helperText={touched.eiPartnerCode && errors.eiPartnerCode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                label="Descripción"
                {...getFieldProps(`description`)}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="text"
                label="Categoría"
                {...getFieldProps(`category`)}
                error={Boolean(touched.category && errors.category)}
                helperText={touched.category && errors.category}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="text"
                label="Información extra"
                {...getFieldProps(`extraInfo`)}
                error={Boolean(touched.extraInfo && errors.extraInfo)}
                helperText={touched.extraInfo && errors.extraInfo}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Stack>
                <Typography variant="h6">Imágenes</Typography>
              </Stack>
              <Stack mb={2}>
                <UploadImage
                  files={files}
                  onSelected={handleOnSelected}
                  onDeleted={handleOnDeleted}
                  onMainSelected={handleOnSelectMainImg}
                  maxFileSize={maxFileImg}
                  onMaxFileSizeError={handleMaxSizeFile}
                  accept={imgAccepted}
                  maxFilesAttach={3 - product?.images?.length ?? 0}
                />
              </Stack>
              <Divider />
              <Stack>
                <UploadImage
                  files={oldFiles}
                  onDeleted={handleOnDeleteImgOld}
                  maxFilesAttach={0}
                  onMainSelected={handleOnSelectMainImgOld}
                />
              </Stack>
            </Grid> */}
          </Grid>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 3 }}
        >
          <Button fullWidth size="large" type="submit" variant="contained">
            Editar
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
