import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Stack, TextField, CircularProgress, Button } from "@material-ui/core";

function CreationMarketplaceForm(props) {
  const CreateMarketplaceSchema = Yup.object().shape({
    name: Yup.string().required(`La descripción es requerida`),
    partnerId: Yup.string(),
    typeAuthentication: Yup.string().required(
      `El tipo de autenticación es requerido`
    ),
    nameAuthentication: Yup.string(),
    token: Yup.string().required(`El valor del token es requerido`),
    config: Yup.object(),
  });

  const formik = useFormik({
    initialValues: {
      name: ``,
      partnerId: ``,
      typeAuthentication: ``,
      nameAuthentication: ``,
      token: ``,
      config: ``,
    },
    validationSchema: CreateMarketplaceSchema,
    onSubmit: ({
      name,
      partnerId,
      typeAuthentication,
      nameAuthentication,
      token,
      config,
    }) => {
      props.onCreateMarketplace({
        name,
        partnerId,
        typeAuthentication,
        nameAuthentication,
        token,
        config: JSON.parse(config),
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} pt={2}>
          <TextField
            fullWidth
            type="text"
            label="Descripción"
            {...getFieldProps(`name`)}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            fullWidth
            type="text"
            label="Código de negocio"
            {...getFieldProps(`partnerId`)}
            error={Boolean(touched.partnerId && errors.partnerId)}
            helperText={touched.partnerId && errors.partnerId}
          />
          <TextField
            fullWidth
            type="text"
            label="Tipo de autenticación"
            {...getFieldProps(`typeAuthentication`)}
            error={Boolean(
              touched.typeAuthentication && errors.typeAuthentication
            )}
            helperText={touched.typeAuthentication && errors.typeAuthentication}
          />
          <TextField
            fullWidth
            type="text"
            label="Nombre de la variable de autenticación"
            {...getFieldProps(`nameAuthentication`)}
            error={Boolean(
              touched.nameAuthentication && errors.nameAuthentication
            )}
            helperText={touched.nameAuthentication && errors.nameAuthentication}
          />
          <TextField
            fullWidth
            type="text"
            label="Token"
            {...getFieldProps(`token`)}
            error={Boolean(touched.token && errors.token)}
            helperText={touched.token && errors.token}
          />
          <TextField
            fullWidth
            type="text"
            label="Configuración extra"
            {...getFieldProps(`config`)}
            error={Boolean(touched.config && errors.config)}
            helperText={touched.config && errors.config}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          <Button
            fullWidth
            disabled={props.isLoading}
            size="large"
            type="submit"
            variant="contained"
          >
            {props.isLoading ? <CircularProgress /> : "Crear"}
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default CreationMarketplaceForm;
