import { useState } from "react";
import { makeStyles } from "@material-ui/styles";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { Sync as SyncIcon } from "@material-ui/icons";

import TableComponent from "../../components/table";
import Loader from "../../components/loader";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "12px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  refreshBtn: {
    marginLeft: "auto !important",
    marginRight: "12px",
  },
}));

function Stores(props) {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { stores, store } = props.state;

  const columns = [
    { id: "sku", label: "SKU", minWidth: 100, align: "center" },
    { id: "description", label: "Descripcion", minWidth: 100, align: "center" },
    {
      id: "address",
      label: "Direccion",
      minWidth: 100,
      align: "center",
    },
    {
      id: "active",
      label: "Estado",
      minWidth: 100,
      align: "center",
    },
  ];

  return !isLoading ? (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div>
          <Typography variant="h4">Tiendas</Typography>
        </div>
        <div className={classes.refreshBtn}>
          <Button
            variant="contained"
            startIcon={<SyncIcon />}
            onClick={() => getStoresBySAP()}
          >
            Actualizar tiendas
          </Button>
        </div>
      </div>
      <TableComponent
        {...props}
        data={stores}
        columns={columns}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        page={page}
        onSelected={props.onSelectedStore}
        setIsOpenDeleteDialog={setIsOpenDeleteDialog}
        searchParameter={"description"}
        onActiveStore={onActiveStore}
      />
      {/* Delete Dialog */}
      {store && (
        <Dialog
          fullWidth
          scroll="paper"
          maxWidth="sm"
          open={isOpenDeleteDialog}
          onClose={() => setIsOpenDeleteDialog(false)}
          aria-labelledby="dialog-delete-user"
          aria-describedby="dialog-delete-user-description"
        >
          <DialogTitle id="dialog-delete-user">
            {store?.active === "Activo" ? "Inactivar tienda" : "Activar tienda"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{`¿Está seguro que desea ${
              store?.active === "Activo" ? "inactivar" : "activar"
            } la tienda ${store?.description} ?`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isLoading}
              onClick={async () => {
                props.onSelectedStore(undefined);
                setIsOpenDeleteDialog(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                store?.active === "Activo"
                  ? onDeleteStore()
                  : onActiveStore(store?.id);
                setIsOpenDeleteDialog(false);
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                `Si, ${store?.active === "Activo" ? "inactivar" : "activar"}`
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  ) : (
    <Loader />
  );

  function handleChangePage(event, newPage) {
    // props.history.push(`?qpage=${newPage}&rows=${rowsPerPage}`);
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  async function onDeleteStore() {
    setIsLoading(true);
    await props.onDeleteStore(store.id);
    await props.onSelectedStore(undefined);
    await props.getAllStores();
    setIsLoading(false);
    setIsOpenDeleteDialog(false);
  }

  async function getStoresBySAP() {
    setIsLoading(true);
    await props.getStoresBySAP();
    await props.getAllStores();
    setIsLoading(false);
  }

  async function onActiveStore(body) {
    setIsLoading(true);
    await props.onActiveStore(body);
    await props.getAllStores();
    setIsLoading(false);
  }
}

export default Stores;
