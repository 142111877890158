import React from "react";
import { Snackbar, Alert as MuiAlert } from "@material-ui/core";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarAlert(props) {
  const handleClose = (event, reason) => {
    if (reason === `clickaway`) {
      return props.setOpen(false);
    }
  };

  const handleAlertType = () => {
    if (props.error) {
      return (
        <Alert severity="error" sx={{ width: `100%` }}>
          {typeof props.message === `string` ? props.message : ``}
        </Alert>
      );
    }

    return (
      <Alert severity="success" sx={{ width: `100%` }}>
        {typeof props.message === `string` ? props.message : ``}
      </Alert>
    );
  };

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: `bottom`, horizontal: `right` }}
      onClose={handleClose}
    >
      {handleAlertType()}
    </Snackbar>
  );
}
