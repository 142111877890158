import { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import DateObject from "react-date-object";
import { CSVLink, CSVDownload } from "react-csv";
import UploadCSV from "./csvUploadDiscountProducts";

import {
  Card,
  Stack,
  Typography,
  TextField,
  MenuItem,
  Button,
  IconButton,
  Tooltip,
  InputAdornment,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  Store as StoreIcon,
  Download as DownloadIcon,
} from "@material-ui/icons";
import { DataGrid, esES } from "@mui/x-data-grid";
import SearchIcon from "@material-ui/icons/Search";

import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";


import Loader from "./loader";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "32px",
  },
  container: {
    display: "flex",
  },
  containerInfo: {
    display: "flex",
    alignItems: "center",
    marginLeft: "32px",
  },
  search: {
    marginTop: "32px",
    marginLeft: "32px",
    marginBottom: "12px",
  },
}));

export default function UpdateDiscountProducts(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    openDialogDelete: false,
    marketplace: {},
    currDiscount: {},
    products: undefined,
    storeName: ``,
    storeSku: ``,
    openDialogEdit: false,
    isLoading: false,
    editRowsModel: {},
    selectedIds: [],
    oldIds: [],
  });
  
  const [isOpenCSVDialog, setOpenCSVDialog] = useState(false);
  const [isAttachement, setIsAttachment] = useState();
  const [productsBySku, setProductsBySku] = useState([]);
  
  const [search, setSearch] = useState("");
  const { products, isLoading, editRowsModel, selectedIds } = state;

  const getDataByDiscount = async () => {
    try {

        const ids = props.state.listProductsDiscount.rows?.map(
            ({ ProductId }) => ProductId
        );

        const mapProduct = props.state.allProducts?.rows?.map(
            ({
              id,
              description,
              sku
            }) => {
              return {
                id,
                sku: Number(sku),
                description,
              };
            }
        );

        const mappedData = [];

        props.state.allProducts?.rows?.forEach( ({id,sku}) => { mappedData[Number(sku)]=id; });

        setProductsBySku(mappedData)

        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            products: mapProduct,
            selectedIds: ids,
        }));
    } catch (err) {
      console.log(`Error get discount by id`, err);
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }

  };

  const handleOnSelectedProducts = (ids) => {
    setState((prevState) => ({ ...prevState, selectedIds: ids }));
  };

  const handleSaveConfiguration = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const payload = selectedIds?.map((item) => ({id:item}))

      console.log("PAYLOAD:")
      console.log(payload)

      await props.onUpdateProductsDiscount(props.state.currDiscount.id, payload)
      props.getAllProductsDiscount(props.state.currDiscount.id)
      setState((prevState) => ({ ...prevState, isLoading: false }));

    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error save update config by store and marketplace`, error);
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const handleEditRowsModelChange = useCallback((model) => {
    setState((prevState) => ({ ...prevState, editRowsModel: model }));
  }, []);

  const handleCellEditCommit = useCallback(
    (data) => {
      const { field, value, id } = data;
      setState((prevState) => {
        const { products } = prevState;
        const newProducts = [...products];
        const findProduct = newProducts.find((product) => product.id === id);
        findProduct[field] = value;

        return { ...prevState, products: newProducts };
      });
    },
    [products]
  );
  useEffect(async () => {
//    await props.getAllProductsDiscount(props.state.currDiscount.id)
    console.log("DONE!!!")
  },[""])

  useEffect(async () => {
//    await getDataMarketplace();
//    await getDataByStore();
    await getDataByDiscount();

    return () => {
      setState({
        openDialogDelete: false,
        marketplace: {},
        products: undefined,
        storeName: ``,
        storeSku: ``,
        openDialogEdit: false,
        isLoading: false,
        editRowsModel: {},
        selectedIds: [],
        oldIds: [],
      });
    };
  }, [props.store]);

  const columns = [
    { field: `sku`, headerName: `SKU`, minWidth: 200 },
    {
      field: `description`,
      headerName: `Descripción`,
      minWidth: 600,
    },
  ];
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.containerInfo}>
            <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Volver">
                <IconButton
                color="primary"
                onClick={() => {
                    props.setIsDiscountSelected(false);
                    props.onSelectedDiscount(undefined);
                }}
                >
                <ArrowBackIcon color="primary" />
                </IconButton>
            </Tooltip>
            <Typography
                variant="body1"
                sx={{ fontWeight: 600 }}
                mx={1}
            >{`Descuento: ${props.state.currDiscount?.name}`}</Typography>
            </div>
        </div>
        <div style={{ marginLeft: "auto", marginRight: "32px" }}>
          <Button
              onClick={() => setOpenCSVDialog(true)}
              variant="outlined"
              disabled={ false}
              style={{ marginRight: "16px" }}
            >
              Cargar csv
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              await handleSaveConfiguration();

              //props.setIsUpdateInventory(false);
            }}
          >
            Guardar configuración
          </Button>
        </div>
      </div>
      <TextField
        id="input-with-icon-textfield"
        className={classes.search}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="standard"
        placeholder="Buscar..."
      />
      {products !== undefined && !isLoading ? (
        products.length > 0 ? (
          <Stack width="100%" style={{ margin: "auto", marginTop: "24px" }}>
            <DataGrid
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              onCellEditCommit={handleCellEditCommit}
              editRowsModel={editRowsModel}
              onEditRowsModelChange={handleEditRowsModelChange}
              onSelectionModelChange={handleOnSelectedProducts}
              selectionModel={selectedIds}
              autoHeight
              loading={isLoading}
              rows={products.filter(
                (item) =>
                  item.sku
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  item.description
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase())
              )}
              columns={columns}
              pageSize={25}
              checkboxSelection
              disableSelectionOnClick
              disableColumnMenu
              disableMultipleColumnsFiltering
            />
          </Stack>
        ) : (
          <Card style={{ marginTop: "24px" }}>
            <Stack display="flex" justifyContent="center" p={3} width="100%">
              <Typography variant="body1" textAlign="center">
                Este descuento no tiene productos asociados, por favor actualice el
                inventario para continuar
              </Typography>
            </Stack>
          </Card>
        )
      ) : (
        <Loader />
      )}
      {/* upload csv config  */}
      <Dialog
          fullWidth
          scroll="paper"
          maxWidth="sm"
          open={isOpenCSVDialog}
          onClose={async () => {
            setOpenCSVDialog(false);
          }}
          aria-labelledby="dialog-edit-marketplace-load-csv"
          aria-describedby="dialog-edit-marketplace-description-load-csv"
        >
          <DialogTitle id="dialog-edit-marketplace-load-csv">{`Cargar CSV de configuración`}</DialogTitle>
          <DialogContent>
            <UploadCSV
              onUpload={handleProcessAttachCsvToUpload}
              onSubmit={handleOnSubmitAttachCsv}
              isLoading={isLoading}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                setOpenCSVDialog(false);
              }}
              disabled={props.isLoading}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );

  function handleProcessAttachCsvToUpload(data) {
    if (data.length) {
      const payload = {data:data}
      setIsAttachment(payload);
    }
  }
  
  async function handleOnSubmitAttachCsv() {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      setOpenCSVDialog(false);
      console.log(isAttachement)
      console.log(productsBySku)

      const ids = isAttachement.data?.map(
          ({ sku }) => productsBySku[sku] 
      );

      handleOnSelectedProducts(ids)

//      await props.onCreateConfiguration(isAttachement);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error save update config by store and marketplace`, error);
    }
    setState((prevState) => ({ ...prevState, isLoading: false }));
  }

}

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}
