import { Switch, Route } from "react-router-dom";

import Main from "../pages/Main";

export const MainRouter = (props) => {
  return (
    <>
      <Switch>
        <Route exact path="/main" component={() => <Main {...props} />} />
      </Switch>
    </>
  );
};
