import { useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Stack,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

function UpdateMarketplaceForm({ marketplace, onUpdateMarketplace }) {
  const [isChecked, setIsChecked] = useState(
    marketplace.active === "Activo" ? true : false
  );
  const CreateMarketplaceSchema = Yup.object().shape({
    name: Yup.string().required(`La descripción es requerida`),
    partnerId: Yup.string(),
    typeAuthentication: Yup.string().required(
      `El tipo de autenticación es requerido`
    ),
    nameAuthentication: Yup.string(),
    token: Yup.string().required(`El valor del token es requerido`),
    config: Yup.object(),
  });

  const formik = useFormik({
    initialValues: {
      name: marketplace.name,
      partnerId: marketplace.partnerId ?? ``,
      typeAuthentication: marketplace.typeAuthentication,
      nameAuthentication: marketplace.nameAuthentication,
      token: marketplace.token,
      config: JSON.stringify(marketplace.config),
    },
    validationSchema: CreateMarketplaceSchema,
    onSubmit: ({
      name,
      partnerId,
      typeAuthentication,
      nameAuthentication,
      token,
      config,
    }) => {
      onUpdateMarketplace({
        id: marketplace.id,
        name,
        partnerId,
        typeAuthentication,
        nameAuthentication,
        token,
        config: JSON.parse(config),
        active: isChecked,
      });
    },
  });

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} pt={2}>
          <FormControlLabel
            control={<Switch checked={isChecked} onChange={handleChange} />}
            label="Activo"
          />
          <TextField
            fullWidth
            type="text"
            label="Descripción"
            {...getFieldProps(`name`)}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            fullWidth
            type="text"
            label="Código de negocio"
            {...getFieldProps(`partnerId`)}
            error={Boolean(touched.partnerId && errors.partnerId)}
            helperText={touched.partnerId && errors.partnerId}
          />
          <TextField
            fullWidth
            type="text"
            label="Tipo de autenticación"
            {...getFieldProps(`typeAuthentication`)}
            error={Boolean(
              touched.typeAuthentication && errors.typeAuthentication
            )}
            helperText={touched.typeAuthentication && errors.typeAuthentication}
          />
          <TextField
            fullWidth
            type="text"
            label="Nombre de la variable de autenticación"
            {...getFieldProps(`nameAuthentication`)}
            error={Boolean(
              touched.nameAuthentication && errors.nameAuthentication
            )}
            helperText={touched.nameAuthentication && errors.nameAuthentication}
          />
          <TextField
            fullWidth
            type="text"
            label="Token"
            {...getFieldProps(`token`)}
            error={Boolean(touched.token && errors.token)}
            helperText={touched.token && errors.token}
          />
          <TextField
            fullWidth
            type="text"
            label="Configuración extra"
            {...getFieldProps(`config`)}
            error={Boolean(touched.config && errors.config)}
            helperText={touched.config && errors.config}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          <Button fullWidth size="large" type="submit" variant="contained">
            Editar
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default UpdateMarketplaceForm;
