import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Paper, Grid, Typography, Hidden } from "@material-ui/core";

import LoginForm from "../../components/loginForm";
import SnackbarAlert from "../../components/snackbarAlert";
import { TOKEN_KEY } from "../../config";

import service from "./service";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
  },
  banner: {
    height: "100vh",
  },
  paper: {
    height: "100%",
    padding: "12px",
    borderRadius: "24px",
  },
  img: {
    width: "75px",
  },
  formContainer: {
    marginTop: "13%",
    textAlign: "center",
  },
  containerMsg: {
    textAlign: "center",
  },
  containerImg: {
    display: "flex",
    justifyContent: "center",
  },
}));

function Login(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState();

  useEffect(() => {
    if (localStorage.getItem(TOKEN_KEY)) props.history.replace("/main");
  });

  return (
    <Grid container spacing={2} className={classes.root}>
      <Hidden mdDown={true}>
        <Grid item md={4} lg={4} xl={4} className={classes.banner}>
          <Paper elevation={10} className={classes.paper}>
            <img src="/static/logo.png" alt="logo" className={classes.img} />
            <div className={classes.containerMsg}>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                Hola, bienvenido de nuevo
              </Typography>
            </div>
            <div className={classes.containerImg}>
              <img
                src="/static/illustrations/illustration_login.png"
                alt="login"
              />
            </div>
          </Paper>
        </Grid>
      </Hidden>

      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        xl={8}
        className={classes.formContainer}
      >
        <div>
          <Typography variant="h4" gutterBottom>
            Iniciar sesión
          </Typography>
          <Typography sx={{ color: `text.secondary` }}>
            Ingrese sus datos a continuación.
          </Typography>
          <LoginForm
            onSignIn={onSignIn}
            isLoading={isLoading}
            setPage={props.setPage}
          />
        </div>
      </Grid>
      <SnackbarAlert
        error={error}
        open={open}
        setOpen={setOpen}
        message={message}
      />
    </Grid>
  );

  async function onSignIn(email, password) {
    setIsLoading(true);
    await service
      .onSignIn({ email, password })
      .then((res) => {
        localStorage.setItem(TOKEN_KEY, res.token);
        localStorage.setItem("user", JSON.stringify(res.user));
      })
      .then(() => props.history.replace("/main"))
      .catch((err) => {
        setOpen(true);
        setError(true);
        setMessage(err);
        setIsLoading(false);
      });
  }
}

export default Login;
