import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

function SidenavItems(props) {
  const { currentUser } = props.state;
  return props.items.map((item, index) => (
    <ListItem
      disabled={props.isLoading}
      key={index}
      button
      onClick={() => {
        props.setPage(item.id);
      }}
      style={{
        marginBottom: "12px",
        color: props.page === item.id ? "#00AB55" : "#75838F",
      }}
      selected={props.page === item.id}
      disabled={
        item.title === "Usuarios" && currentUser.roleId === 3 ? true : false
      }
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  ));
}

export default SidenavItems;
