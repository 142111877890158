import React from "react";
import { Button, Stack, IconButton, Paper, Tooltip } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {
  Close as CloseIcon,
  PhotoCamera as PhotoCameraIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
} from "@material-ui/icons";
const Input = styled(`input`)({
  display: `none`,
});

const PaperStyled = styled(Paper)(({ theme }) => ({
  display: `flex`,
  flexDirection: `row`,
  flex: `1 1 auto`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `center`,
  width: `150px`,
  height: `150px`,
  "& > button": {
    position: `absolute`,
    right: theme.spacing(1),
  },
}));
// ----------------------------------------------------------------------

export default function UploadImage({
  disabled = false,
  files = [],
  accept = `image/*`,
  id = `buttonUpload`,
  onSelected = () => {},
  onDeleted = () => {},
  onMainSelected = () => {},
  maxFileSize = null,
  onMaxFileSizeError = () => {},
  maxFilesAttach = 3,
}) {
  return (
    <Stack direction="column" alignItems="center" spacing={2}>
      {files.length < maxFilesAttach && (
        <label htmlFor={id}>
          <Input
            accept={accept}
            id={id}
            multiple
            type="file"
            onChange={(event) => {
              const reader = new FileReader();
              const file = event.target.files[0];
              if (maxFileSize && file.size > maxFileSize) {
                onMaxFileSizeError(true);
                return;
              }
              onMaxFileSizeError(false);
              let name = ``;
              reader.addEventListener(
                `load`,
                () =>
                  onSelected({ file: reader.result, name, originalFile: file }),
                false
              );
              name = file.name;
              if (file) {
                reader.readAsDataURL(file);
              }
            }}
          />
          <Button
            variant="contained"
            component="span"
            startIcon={<PhotoCameraIcon />}
          >
            Cargar imagen
          </Button>
        </label>
      )}

      <Stack mt={3} direction="row" spacing={2}>
        {files.map(({ file, main }, key) => (
          <Stack key={key} position="relative">
            <PaperStyled sx={{ backgroundImage: `url(${file})` }}>
              {!disabled && (
                <IconButton size="small" onClick={() => onDeleted(key)}>
                  <CloseIcon color="primary" />
                </IconButton>
              )}
            </PaperStyled>
            <Tooltip title="Marcar como principal">
              <IconButton
                size="small"
                sx={{ position: `absolute`, bottom: 0 }}
                onClick={() => onMainSelected(key)}
                color="error"
              >
                {main ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>
            </Tooltip>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
