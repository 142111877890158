import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import DateObject from "react-date-object";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Pagination,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
  TablePagination,  
} from "@material-ui/core";


import TableComponent from "../../components/table";
import Loader from "../../components/loader";
import service from "./service";


import {
  ArrowBack as ArrowBackIcon,
  Search,
} from "@material-ui/icons";
import zIndex from "@material-ui/core/styles/zIndex";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "12px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  itemsContainer: {
    marginTop: "16px",
    textAlign: "center",
  },
  content: {
    textDecoration: "none",
  },
  paper: {
    height: "200px",
    padding: "8px",
    borderRadius: "18px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  paper_product: {
    marginRight: "12px",
    marginBottom: "12px",
    padding: "12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  product_row:{
    "&:hover": {
      cursor: "pointer",
    }
  },
  title: {
    textTransform: "uppercase",
    fontWeight: 600,
    textDecoration: "none",
  },
  qty: {
    marginTop: "32px",
    textDecoration: "none",
  },
  refreshBtn: {
    marginLeft: "auto !important",
    marginRight: "12px",
  },
  gridContainer: {
    marginTop: "24px",
  },
  skuTitle: {
    fontWeight: "600",
  },
  pagination: {
    textAlign: "center",
    display: "flex",
    position: "sticky",
    bottom: "0",
    padding: "12px", 
    backgroundImage: "linear-gradient(#ffffff0f, #ffffffcf,#ffffff,#ffffff)"
  },
  description: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  search: {
    marginTop: "24px",
    marginLeft: "24px"
  },
  logDescription:{
    maxWidth:"300px",
    minWidth:"300px"
  },
  datePickerContainer:{
    display: "inline-block",
    marginTop: "24px",
    position: "relative",
    float: "right",
    zIndex:1000
  },
  datePickerInputDiv:{
    display: "inline-block",
    marginRight: "24px",
  }
}));

function Auditing(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(props.startFilter);
  const { listAuditing } = props.state;
  const [filter, setFilter] = useState({
    page: 0,
    search: "",
    size: 100,
  })

  const[order,setOrder] = useState("asc")
  const[orderBy,setOrderBy] = useState("createdAt")
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(1);

  const columns = [
    { id: "createdAt", label: "Fecha", minWidth: 100, align: "center" },
    { id: "module", label: "Módulo", minWidth: 100, align: "center" },
    { id: "action", label: "Acción", minWidth: 100, align: "center" },
    {
      id: "user",
      label: "Usuario",
      minWidth: 100,
      align: "center",
    },
    {
      id: "description",
      label: "Descripción",
      minWidth: 100,
      maxWidth:200,
      align: "center",
    },
  ];

  useEffect(async () => {
    await props.getAllAuditing(0,20,"")
    console.log("DONE!!!")
  },[""])

  useEffect(async () => {

    console.log("Cambie...")
    console.log("StarDate: " + startDate)
    console.log("EndDate: " + endDate)
    await props.getAllAuditing(0,20, search, orderBy, order, startDate, endDate);
    
  },[startDate, endDate])

  return !props.isLoading ? (
    
    <div className={classes.root}>
    <div className={classes.titleContainer}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5">{`Log de Auditoría`}</Typography>
      </div>
    </div>

    <Paper
      style={{
        marginTop: "24px",
        borderRadius: "24px",
      }}
      elevation={5}
    >
      <TextField
        id="input-with-icon-textfield"
        className={classes.search}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        onKeyPress={handleKeyPress}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {search !== "" && (
                <Button
                  style={{ marginRight: "8px", marginBottom: "8px" }}
                  variant="contained"
                  onClick={() => handleFilterData(search)}
                >
                  Buscar
                </Button>
              )}

              {search !== "" && (
                <Button
                  style={{ marginRight: "8px", marginBottom: "8px" }}
                  variant="outlined"
                  onClick={() => handleClearFilterData()}
                >
                  Limpiar
                </Button>
              )}
            </>
          ),
        }}
        variant="standard"
        placeholder="Buscar..."
      />
      <div className={classes.datePickerContainer}>
        <div className={classes.datePickerInputDiv}>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
          placeholderText="Fecha desde"
          dateFormat="yyyy/MM/dd"
          isClearable
          showIcon
          locale={es}
        />
        </div>
        <div className={classes.datePickerInputDiv}>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="Fecha hasta"
          dateFormat="yyyy/MM/dd"
          isClearable
          showIcon
          locale={es}
        />
        </div>
      </div>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell key={"createdAt"} align={"center"}>
                <TableSortLabel
                  active={orderBy === "createdAt"}
                  direction={orderBy === "createdAt" ? order : "desc"}
                  onClick={() => createSortHandler("createdAt")}
                >
                  Fecha
                </TableSortLabel>
              </TableCell>
              <TableCell key={"module"} align={"center"}>
                <TableSortLabel
                  active={orderBy === "module"}
                  direction={orderBy === "module" ? order : "asc"}
                  onClick={() => createSortHandler("module")}
                >
                  Módulo
                </TableSortLabel>
              </TableCell>
              <TableCell key={"action"} align={"center"}>
                <TableSortLabel
                  active={orderBy === "action"}
                  direction={orderBy === "action" ? order : "asc"}
                  onClick={() => createSortHandler("action")}
                >
                  Acción
                </TableSortLabel>
              </TableCell>
              <TableCell key={"user"} align={"center"}>
                <TableSortLabel
                  active={orderBy === "username"}
                  direction={orderBy === "username" ? order : "asc"}
                  onClick={() => createSortHandler("username")}
                >
                  Usuario
                </TableSortLabel>
              </TableCell>
              <TableCell key={"description"} align={"center"}>
                
                  Info adicional
                
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listAuditing.rows?.map((log) => {
              //var idx = 0; 
              //if(prod.Stores.length > 1 && parseInt(prod.Stores[0].ProductStores.basePrice) == 0){idx = 1};

              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={log.id} className={classes.product_row}
                  onClick={() => {
                    //setProduct(prod);
                    //toProductDetail();
                  }}>
                  <TableCell key={"createdAt"} align={"center"}>
                    <Typography
                      variant="p"
                      className={""}
                    >
                      {new DateObject(log.createdAt).format("YYYY/MM/DD hh:mm:ss a")}
                    </Typography>
                  </TableCell>
                  <TableCell key={"module"} align={"center"}>
                    <Typography
                      variant="p"
                      className={""}
                    >
                      {log.module}
                    </Typography>
                  </TableCell>
                  <TableCell key={"action"} align={"center"}>
                    <Typography
                      variant="p"
                      className={""}
                    >
                      {log.action}
                    </Typography>
                  </TableCell>
                  <TableCell key={"user"} align={"center"}>
                    <Typography
                      variant="p"
                      className={""}
                    >
                      {log.Users.firstName + " "+ log.Users.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell key={"description"} align={"center"} className={classes.logDescription}>
                    <Typography
                      variant="p"
                      className={""}
                    >
                      { new String(log.description? log.description:"").replaceAll(",", ", ")}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>

    <div className={classes.pagination} >
      <Pagination
        page={page}
        count={props.state.listAuditing.pages}
        color="primary"
        sx={{ margin: `auto` }}
        onChange={handleChangeAuditPage}
      />
    </div>
    
  </div>
  ) : (
    <Loader />
  );

  function handleChangePage(event, newPage) {
    // props.history.push(`?qpage=${newPage}&rows=${rowsPerPage}`);
    //setPageStore(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  async function handleFilterData(search) {
    await props.getAllAuditing(0,20, search, orderBy, order, startDate, endDate);
  }


  async function handleChangeAuditPage(event, newPage) {
    setPage(newPage);
    await props.getAllAuditing(newPage - 1,20,search, orderBy, order, startDate, endDate);
  }

  async function handleClearFilterData(search) {
    //console.log("StoreID: " + storeId);
    await props.getAllAuditing(0,20,"", orderBy, order, startDate, endDate);
    setSearch("");
    setPage(1);
  }


  function handleKeyPress(event) {
    if(event.key === 'Enter'){
      handleFilterData(search);
    }
  }

    async function createSortHandler(column){
    let direction = "asc";
    if (orderBy === column) {
      direction = order === 'asc' ? 'desc' : 'asc';
      setOrder(direction)
    } else {
      setOrder(direction);
      setOrderBy(column);
    }
    setPage(1);
    await props.getAllAuditing(0,20, search, column, direction, startDate, endDate);
  };

    
}

export default Auditing;


