import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";

import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TableSortLabel,
  TablePagination,
  TableHead,
  IconButton,
  TextField,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import StoreIcon from "@material-ui/icons/Store";
import CategoryIcon from "@material-ui/icons/Category";
import SearchIcon from "@material-ui/icons/Search";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  text: {
    width: "50px",
    background: "#fffff",
    borderRadius: "20px",
    color: "#00AB55",
    border: "solid 1px #00AB55",
    fontWeight: "600",
    fontSize: "12px",
    padding: "5px",
  },
  textInactive: {
    width: "50px",
    background: "#fffff",
    borderRadius: "20px",
    color: "red",
    border: "solid 1px red",
    fontWeight: "600",
    fontSize: "12px",
    padding: "5px",
  },
  search: {
    marginTop: "32px",
    marginLeft: "58px",
    marginBottom: "12px",
  },
}));

function TableComponent(props) {
  const [search, setSearch] = useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(1);
  const classes = useStyles();
  const {
    rowsPerPage,
    page,
    onSelected,
    handleChangePage,
    handleChangeRowsPerPage,
    data,
    setIsOpenDeleteDialog,
    setIsOpenUpdateDialog,
    searchParameter,
    isAddedCategory,
    isAddedStore,
    onActiveStore,
    isStoreReport,
    setStoreName,
  } = props;

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <Paper
      style={{
        marginTop: "24px",
        borderRadius: "24px",
      }}
      elevation={5}
    >
      <TextField
        id="input-with-icon-textfield"
        className={classes.search}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="standard"
        placeholder="Buscar..."
      />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <TableCell key={column.id} align={column.align}>
                  <TableSortLabel
                    active={true}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell align={"center"} style={{ minWidth: 100 }}>
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .filter((item) =>
                item[searchParameter]
                  .toLowerCase()
                  .includes(search.toLowerCase())
              )
              .sort(getComparator(order, orderBy))

              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {props.columns.map((column) => {
                      const value =
                        column.id === "role"
                          ? row[column.id].description
                          : column.id === "marketplaceCategory" ||
                            column.id === "marketplaceStore"
                          ? row[column.id].length
                          : column.id === "activateByUser"
                          ? `${row[column.id].firstName} ${
                              row[column.id].lastName
                            }`
                          : row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Typography
                            variant="p"
                            className={
                              column.id === "active"
                                ? value === "Activo"
                                  ? classes.text
                                  : classes.textInactive
                                : ""
                            }
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </Typography>
                        </TableCell>
                      );
                    })}

                    <TableCell align="center">
                      {isAddedStore && (
                        <Tooltip title="Agregar tiendas" placement="top">
                          <IconButton
                            color="primary"
                            onClick={async () => {
                              await props.getInventoryUpdate(row.id);

                              onSelected(row);
                              isAddedStore(true);
                            }}
                          >
                            <StoreIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {isAddedCategory && (
                        <Tooltip title="Agregar categorias" placement="top">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              onSelected(row);
                              isAddedCategory(true);
                            }}
                          >
                            <CategoryIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {setIsOpenUpdateDialog && (
                        <Tooltip title="Editar" placement="top">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              onSelected(row);
                              setIsOpenUpdateDialog(true);
                            }}
                          >
                            <EditIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {isStoreReport && (
                        <Tooltip title="Ver productos" placement="top">
                          <IconButton
                            color="primary"
                            onClick={async () => {
                              //await props.getInventoryUpdate(row.id);

                              setStoreName(row.description);
                              onSelected(row);
                              isStoreReport(true);
                            }}
                          >
                            <StoreIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      )}

                      {setIsOpenDeleteDialog && onActiveStore ? (
                        <Tooltip
                          title={
                            row.active === "Activo" ? "Inactivar" : "Activar"
                          }
                          placement="top"
                        >
                          <IconButton
                            color="primary"
                            onClick={() => {
                              onSelected(row);
                              setIsOpenDeleteDialog(true);
                            }}
                          >
                            {row.active === "Activo" ? (
                              <NotInterestedIcon color="primary" />
                            ) : (
                              <CheckCircleIcon color="primary" />
                            )}
                          </IconButton>
                        </Tooltip>
                      ) : setIsOpenDeleteDialog ? (
                        <Tooltip title={"Eliminar"} placement="top">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              onSelected(row);
                              setIsOpenDeleteDialog(true);
                            }}
                          >
                            <DeleteIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      ) : undefined}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        backIconButtonProps={{ style: { color: "#00AB55" } }}
        nextIconButtonProps={{ style: { color: "#00AB55" } }}
        labelDisplayedRows={({ from, to, count }) => {
          const countText = count !== -1 ? count : `more than ${to}`;
          return `${from}-${to} de ${countText}`;
        }}
        labelRowsPerPage={"Filas por pagina"}
      />
    </Paper>
  );
}

export default TableComponent;
