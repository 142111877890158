import { useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import queryString from "query-string";

import TableComponent from "../../components/table";
import CreationMarketplaceForm from "../../components/creationMarketplaceForm";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";

import UpdateMarketplaceForm from "../../components/updateMarketplaceForm";
import MarketplaceStore from "../../components/marketplaceStore";
import UploadCSV from "../../components/csvUpload";
import Loader from "../../components/loader";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "12px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  refreshBtn: {
    marginLeft: "auto !important",
    marginRight: "12px",
    display: "flex",
  },
  rows: {
    display: "flex",
    justifyContent: "center",
  },
  text: {
    width: "50px",
    background: "#00AB55",
    borderRadius: "20px",
    color: "#ffffff",
    fontWeight: "600",
    fontSize: "12px",
    padding: "5px",
  },
}));

function Marketplace(props) {
  const classes = useStyles();
  const location = useLocation();

  const { qpage = 0 } = queryString.parse(location.search);

  const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenUpdateDialog, setIsOpenUpdateDialog] = useState(false);
  const [isUpdateInventory, setIsUpdateInventory] = useState(false);
  const [isOpenCSVDialog, setOpenCSVDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAttachement, setIsAttachment] = useState();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(parseInt(qpage));

  const [isAddStore, setIsAddStore] = useState(false);
  const [isAddCategory] = useState(false);

  const {
    marketplaces,
    marketplace,
    storesUpdates,
    stores,
    storeMarketplace,
    store,
    updateStoreAndMarketplace,
    currentUser,
  } = props.state;

  const columns = [
    { id: "id", label: "ID", minWidth: 100, align: "center" },
    { id: "name", label: "Nombre", minWidth: 100, align: "center" },
    {
      id: "marketplaceCategory",
      label: "Categorias",
      minWidth: 100,
      align: "center",
    },
    {
      id: "marketplaceStore",
      label: "Tiendas",
      minWidth: 100,
      align: "center",
    },
    {
      id: "activateByUser",
      label: "Registrado por",
      minWidth: 100,
      align: "center",
    },
    {
      id: "active",
      label: "Estado",
      minWidth: 100,
      align: "center",
    },
  ];
  return !isAddStore && !isAddCategory ? (
    props.isLoading ? (
      <Loader />
    ) : (
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <div>
            <Typography variant="h4">Marketplace</Typography>
          </div>
          <div className={classes.refreshBtn}>
            <Button
              onClick={() => setIsOpenCreateDialog(true)}
              variant="contained"
              startIcon={<AddIcon />}
              disabled={currentUser.roleId === 3 ? true : false}
            >
              Agregar marketplace
            </Button>
          </div>
        </div>
        <TableComponent
          {...props}
          data={marketplaces}
          columns={columns}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          onSelected={props.onSelectedMarketplace}
          setIsOpenDeleteDialog={
            currentUser.roleId === 3 ? false : setIsOpenDeleteDialog
          }
          setIsOpenUpdateDialog={
            currentUser.roleId === 3 ? false : setIsOpenUpdateDialog
          }
          isAddedStore={setIsAddStore}
          getInventoryUpdate={props.getInventoryUpdate}
          isAddedCategory={false}
          searchParameter={"name"}
        />

        {/* Create Dialog */}
        <Dialog
          fullWidth
          scroll="paper"
          maxWidth="sm"
          open={isOpenCreateDialog}
          onClose={() => setIsOpenCreateDialog(false)}
          aria-labelledby="dialog-create-user"
          aria-describedby="dialog-create-user-description"
        >
          <DialogTitle id="dialog-create-user">{`Crear Marketplace`}</DialogTitle>
          <DialogContent>
            <CreationMarketplaceForm
              onCreateMarketplace={onCreateMarketplace}
              isLoading={props.isLoading}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={props.isLoading}
              onClick={() => {
                setIsOpenCreateDialog(false);
              }}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete Dialog */}
        {marketplace && (
          <Dialog
            fullWidth
            scroll="paper"
            maxWidth="sm"
            open={isOpenDeleteDialog}
            onClose={() => setIsOpenDeleteDialog(false)}
            aria-labelledby="dialog-delete-user"
            aria-describedby="dialog-delete-user-description"
          >
            <DialogTitle id="dialog-delete-user">
              Eliminar marketplace
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{`¿Está seguro que desea eliminar el marketplace ${marketplace.name}?`}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={props.isLoading}
                onClick={() => {
                  props.onSelectedMarketplace(undefined);
                  setIsOpenDeleteDialog(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => onDeleteMarketplace(marketplace.id)}
                disabled={props.isLoading}
              >
                {props.isLoading ? <CircularProgress /> : `Si, eliminar`}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Update Dialog */}
        {marketplace && (
          <Dialog
            fullWidth
            scroll="paper"
            maxWidth="sm"
            open={isOpenUpdateDialog}
            onClose={() => setIsOpenUpdateDialog(false)}
            aria-labelledby="dialog-delete-user"
            aria-describedby="dialog-delete-user-description"
          >
            <DialogTitle id="dialog-delete-user">
              Editar marketplace
            </DialogTitle>
            <DialogContent>
              <UpdateMarketplaceForm
                marketplace={marketplace}
                onUpdateMarketplace={onUpdateMarketplace}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={props.isLoading}
                onClick={() => {
                  props.onSelectedMarketplace(undefined);
                  setIsOpenUpdateDialog(false);
                }}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* upload csv config  */}
        <Dialog
          fullWidth
          scroll="paper"
          maxWidth="sm"
          open={isOpenCSVDialog}
          onClose={async () => {
            await props.onSelectedStore(undefined);
            setOpenCSVDialog(false);
          }}
          aria-labelledby="dialog-edit-marketplace-load-csv"
          aria-describedby="dialog-edit-marketplace-description-load-csv"
        >
          <DialogTitle id="dialog-edit-marketplace-load-csv">{`Cargar CSV de configuración`}</DialogTitle>
          <DialogContent>
            <UploadCSV
              onUpload={handleProcessAttachFileToUpload}
              onSubmit={handleOnSubmitAttachFile}
              isLoading={isLoading}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                await props.onSelectedStore(undefined);
                setOpenCSVDialog(false);
              }}
              disabled={props.isLoading}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  ) : isAddStore && !isAddCategory ? (
    <MarketplaceStore
      currentUser={currentUser}
      stores={stores}
      store={store}
      setIsAddStore={setIsAddStore}
      onSelectedMarketplace={props.onSelectedMarketplace}
      marketplace={marketplace}
      getInventoryUpdate={props.getInventoryUpdate}
      handleStatusInventoryUpdate={props.handleStatusInventoryUpdate}
      storesUpdates={storesUpdates}
      onCreateStoreInMarketplace={onCreateStoreInMarketplace}
      onStoreMarketplaceSelected={props.onStoreMarketplaceSelected}
      storeMarketplace={storeMarketplace}
      onUpdateStoreMarketplace={onUpdateStoreMarketplace}
      getStoreByID={getStoreByID}
      getUpdateByStoreAndMarketplace={getUpdateByStoreAndMarketplace}
      updateStoreAndMarketplace={updateStoreAndMarketplace}
      saveUpdateByStoreAndMarketplace={saveUpdateByStoreAndMarketplace}
      isUpdateInventory={isUpdateInventory}
      setIsUpdateInventory={setIsUpdateInventory}
      sendInventoryToMarketplaceFromStore={
        props.sendInventoryToMarketplaceFromStore
      }
      onSelectedStore={props.onSelectedStore}
      onDeleteStoreByMarketplace={onDeleteStoreByMarketplace}
      isLoading={props.isLoading}
      onCreateConfiguration={props.onCreateConfiguration}
      onGetHistory={props.onGetHistory}
    />
  ) : (
    <h1>Agregar categoria</h1>
  );

  function handleChangePage(event, newPage) {
    props.history.push(`?qpage=${newPage}`);
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  async function onCreateMarketplace(body) {
    await props.onCreateMarketplace(body);
    await props.getAllMarketplaces();
    setIsOpenCreateDialog(false);
  }

  async function onUpdateMarketplace(body) {
    await props.onUpdateMarketplace(body);
    await props.getAllMarketplaces();
    setIsOpenUpdateDialog(false);
  }

  async function onDeleteMarketplace() {
    await props.onDeleteMarketplace(marketplace.id);
    await props.onSelectedMarketplace(undefined);
    await props.getAllMarketplaces();
    setIsOpenDeleteDialog(false);
  }

  async function onCreateStoreInMarketplace(body) {
    await props.onCreateStoreInMarketplace(body);
    await props.getAllMarketplaces();
  }

  async function onUpdateStoreMarketplace(body) {
    await props.onUpdateStoreMarketplace(body);
    await props.getAllMarketplaces();
    await props.onStoreMarketplaceSelected(undefined);
  }

  async function getStoreByID(id) {
    await props.getStoreByID(id);
  }

  async function getUpdateByStoreAndMarketplace(storeId, marketplaceId) {
    return await props.getUpdateByStoreAndMarketplace(storeId, marketplaceId);
  }

  async function onDeleteStoreByMarketplace(id) {
    await props.onDeleteStoreByMarketplace(id);
    await props.getAllMarketplaces();
  }

  async function saveUpdateByStoreAndMarketplace(body) {
    await props.saveUpdateByStoreAndMarketplace(body);
    await props.getAllMarketplaces();
    await getUpdateByStoreAndMarketplace(store?.id, marketplace?.id);
  }

  function handleProcessAttachFileToUpload(data) {
    if (data.length) {
      setIsAttachment(data);
    }
  }
  async function handleOnSubmitAttachFile() {
    setIsLoading(true);
    try {
      setOpenCSVDialog(false);

      await props.onCreateConfiguration(isAttachement);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error save update config by store and marketplace`, error);
    }
    setIsLoading(false);
  }
}

export default Marketplace;
