import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "50%",
    display: "inline-block",
    marginTop: "32px",
  },
  hiperlink: {
    color: "#00AB55",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

function LoginForm(props) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        `El correo electrónico debe ser una dirección de correo electrónico válida`
      )
      .required(`El correo electrónico es requerido`),
    password: Yup.string().required(`La contraseña es requerida`),
  });

  const formik = useFormik({
    initialValues: {
      email: ``,
      password: ``,
    },
    validationSchema: LoginSchema,
    onSubmit: ({ email, password }) => {
      props.onSignIn(email, password);
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        className={classes.form}
      >
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Correo electrónico"
            {...getFieldProps(`email`)}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? `text` : `password`}
            label="Contraseña"
            {...getFieldProps(`password`)}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ my: 2 }}
          onClick={() => props.setPage(1)}
          className={classes.hiperlink}
        >
          {/* ¿Se te olvidó tu contraseña? */}
          {/* <Link
            component={RouterLink}
            variant="subtitle2"
            to="/auth/forgot-password"
            style={{ textDecoration: "none" }}
          >
          </Link> */}
        </Stack>

        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={Object.keys(errors).length > 0 || props.isLoading}
        >
          {props.isLoading ? <CircularProgress /> : "Ingresar"}
        </Button>
      </Form>
    </FormikProvider>
  );
}

export default LoginForm;
