import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Link,
  Stack,
  TextField,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  form: {
    width: "50%",
    display: "inline-block",
    marginTop: "32px",
  },
  hiperlink: {
    color: "#00AB55",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

function ForgotPasswordForm(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={(event) => {
        event.preventDefault();
        props.onForwotPassword(email);
      }}
      className={classes.form}
    >
      <Stack spacing={3}>
        <TextField
          fullWidth
          autoComplete="username"
          type="email"
          label="Correo electrónico"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ my: 2 }}
        className={classes.hiperlink}
        onClick={() => props.setPage(0)}
      >
        Regresar al inicio de sesión
      </Stack>

      <Button
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={props.isLoading || email === ""}
      >
        {props.isLoading ? <CircularProgress /> : "Recuperar"}
      </Button>
    </form>
  );
}

export default ForgotPasswordForm;
