import { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import DateObject from "react-date-object";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Pagination,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
} from "@material-ui/core";
import {
  Sync as SyncIcon,
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
  Search,
} from "@material-ui/icons";
// import SearchIcon from "@material-ui/icons/Search";

import ProductDetails from "../../components/productDetails";
import Loader from "../../components/loader";
import UpdateProductForm from "../../components/updateProductForm";
import service from "./service";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "12px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  refreshBtn: {
    marginLeft: "auto !important",
    marginRight: "12px",
  },
  gridContainer: {
    marginTop: "24px",
  },
  paper: {
    marginRight: "12px",
    marginBottom: "12px",
    padding: "12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  skuTitle: {
    fontWeight: "600",
  },
  pagination: {
    textAlign: "center",
    display: "flex",
    position: "sticky",
    bottom: "0",
    padding: "12px",
  },
  description: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  search: {
    marginTop: "24px",
  },
}));

function Inventory(props) {
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [pageHistory, setPageHistory] = useState(1);
  const [isProductDetails, setIsProductDetails] = useState(false);
  const [isOpenUpdateDialog, setIsOpenUpdateDialog] = useState(false);
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    page: 0,
    search: "",
    size: 10,
    market: "",
    store: "",
  })

  const { store, market, size } = filter;

  const { inventory } = props.state;
  const { product } = props;


  return !props.isLoading ? (
    !isProductDetails ? (
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <div>
            <Typography variant="h4">Inventario</Typography>
          </div>
          <div className={classes.refreshBtn}>
            <Button
              style={{ marginRight: "12px" }}
              variant="outlined"
              onClick={async () => {
                await props.getHistoryUpdates(pageHistory);
                setIsOpenHistory(true);
              }}
            >
              Historico de actualizaciones
            </Button>
            <Button
              variant="contained"
              startIcon={<SyncIcon />}
              onClick={() => getProductsBySAP()}
            >
              Actualizar inventario
            </Button>
          </div>
        </div>
        <TextField
          fullWidth
          id="input-with-icon-textfield"
          className={classes.search}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {search !== "" && (
                  <Button
                    style={{ marginRight: "8px", marginBottom: "8px" }}
                    variant="contained"
                    onClick={() => handleFilterData(search)}
                  >
                    Buscar
                  </Button>
                )}

                {inventory.pages === 1 && (
                  <Button
                    style={{ marginRight: "8px", marginBottom: "8px" }}
                    variant="outlined"
                    onClick={() => handleClearFilterData()}
                  >
                    Limpiar
                  </Button>
                )}
              </>
            ),
          }}
          variant="standard"
          placeholder="Buscar..."
        />
        <Grid container className={classes.gridContainer}>
          {inventory.rows
            // .filter((item) =>
            //   item.description.toLowerCase().includes(search.toLowerCase())
            // )
            .map((item, index) => (
              <Grid item sm={12} md={6} lg={4} xl={3} key={index}>
                <Paper
                  elevation={5}
                  className={classes.paper}
                  onClick={() => {
                    console.log('item', item.id)
                    props.onSelectedProduct(item.id);
                    setIsProductDetails(true);
                  }}
                >
                  <img src={"/static/placeholder.png"} alt="product" />
                  <div>
                    <div className={classes.skuTitle}>{`SKU: ${parseInt(
                      item.sku
                    )}`}</div>
                    <div className={classes.description}>
                      {item.description}
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
        </Grid>
        <div className={classes.pagination}>
          <Pagination
            page={page}
            count={inventory.pages}
            color="primary"
            sx={{ margin: `auto` }}
            onChange={handleChangePage}
          />
        </div>
        {isOpenHistory && (
          <Dialog
            fullWidth
            scroll="paper"
            maxWidth="md"
            open={isOpenHistory}
            onClose={() => {
              setIsOpenHistory(false);
            }}
            aria-labelledby="dialog-edit-user"
            aria-describedby="dialog-edit-user-description"
          >
            <DialogTitle id="dialog-edit-user">{`Historico de actualización`}</DialogTitle>
            <DialogContent>
              <Paper elevation={5}>
                {props.isLoading ? (
                  "Cargando ..."
                ) : (
                  <TableContainer style={{ marginTop: "32px" }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Estado</TableCell>
                          <TableCell align="center">Fecha</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.historyUpdates.rows.map((item, index) => {
                          return (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center">
                                {item.status === "done"
                                  ? "Completado"
                                  : "No completado"}
                              </TableCell>
                              <TableCell align="center">
                                {new DateObject(item.createdAt).format(
                                  "YYYY/MM/DD hh:mm:ss a"
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                <div className={classes.pagination}>
                  <Pagination
                    page={pageHistory}
                    count={props.historyUpdates?.pages}
                    color="primary"
                    sx={{ margin: `auto` }}
                    onChange={handleChangePageHistory}
                  />
                </div>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsOpenHistory(false)}>Cerrar</Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    ) : (
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Volver">
              <IconButton
                color="primary"
                onClick={() => {
                  props.onSelectedProduct(undefined);
                  setIsProductDetails(false);
                }}
              >
                <ArrowBackIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Typography variant="h5">{`Producto: ${parseInt(
              props.product?.sku
            )}`}</Typography>
          </div>
          <div className={classes.refreshBtn}>
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => setIsOpenUpdateDialog(true)}
            >
              Editar
            </Button>
          </div>
        </div>
        <ProductDetails product={product} />
        {product && (
          <Dialog
            fullWidth
            scroll="paper"
            maxWidth="sm"
            open={isOpenUpdateDialog}
            onClose={() => {
              setIsOpenUpdateDialog(false);
            }}
            aria-labelledby="dialog-edit-user"
            aria-describedby="dialog-edit-user-description"
          >
            <DialogTitle id="dialog-edit-user">{`Editar producto`}</DialogTitle>
            <DialogContent>
              <UpdateProductForm
                product={product}
                handleOnSubmit={onUpdateProduct}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsOpenUpdateDialog(false)}>
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    )
  ) : (
    <Loader />
  );

  async function handleChangeMarket(event, market) {
    setFilter(prevState => ({
      ...prevState,
      market,
    }))
    //await props.getProducts(newPage - 1);
  }

  async function handleChangePage(event, newPage) {
    setPage(newPage);
    await props.getProducts(newPage - 1);
  }

  async function handleChangePageHistory(event, newPage) {
    setPageHistory(newPage);
    await props.getHistoryUpdates(newPage);
  }

  async function handleFilterData(search) {
    await props.getProducts(0, search);
  }

  async function handleClearFilterData(search) {
    await props.getProducts(1);
    setSearch("");
  }

  async function getProductsBySAP() {
    await props.getProductsBySAP();
    await props.getProducts(page);
  }

  async function onUpdateProduct(body) {
    await props.onUpdateProduct(body);
    await props.getProducts(1, search);
    setIsOpenUpdateDialog(false);
  }
}

export default Inventory;
