import { useState } from "react";

import Login from "./login";
import ForgotPassword from "./forgotPassword";

function Auth(props) {
  const [page, setPage] = useState();
  let content;
  switch (page) {
    case 0:
      content = <Login setPage={setPage} {...props} />;
      break;
    case 1:
      content = <ForgotPassword setPage={setPage} {...props} />;
      break;
    case 2:
      content = <h1>Epale bebe</h1>;
      break;
    default:
      setPage(0);
      break;
  }

  return content;
}

export default Auth;
