import { useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import queryString from "query-string";

import TableComponent from "../../components/table";
import CreationUserForm from "../../components/creationUserForm";
import UpdateUserForm from "../../components/updateUserForm";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "12px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  refreshBtn: {
    marginLeft: "auto !important",
    marginRight: "12px",
  },
  rows: {
    display: "flex",
    justifyContent: "center",
  },
  text: {
    width: "50px",
    background: "#00AB55",
    borderRadius: "20px",
    color: "#ffffff",
    fontWeight: "600",
    fontSize: "12px",
    padding: "5px",
  },
}));

function Users(props) {
  const classes = useStyles();
  const location = useLocation();

  const { qpage = 0 } = queryString.parse(location.search);

  const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenUpdateDualog, setIsOpenUpdateDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(parseInt(qpage));
  const { users, roles, user } = props.state;

  const columns = [
    { id: "firstName", label: "Nombre", minWidth: 100, align: "center" },
    { id: "lastName", label: "Apellido", minWidth: 100, align: "center" },
    {
      id: "email",
      label: "Correo Electronico",
      minWidth: 100,
      align: "center",
    },
    {
      id: "role",
      label: "Rol",
      minWidth: 100,
      align: "center",
    },
    {
      id: "active",
      label: "Estado",
      minWidth: 100,
      align: "center",
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div>
          <Typography variant="h4">Usuarios</Typography>
        </div>
        <div className={classes.refreshBtn}>
          <Button
            onClick={() => openCreateDialog()}
            variant="contained"
            startIcon={<AddIcon />}
          >
            Agregar Usuario
          </Button>
        </div>
      </div>
      <TableComponent
        {...props}
        data={users}
        columns={columns}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        page={page}
        onSelected={props.onSelectedUser}
        setIsOpenDeleteDialog={setIsOpenDeleteDialog}
        setIsOpenUpdateDialog={setIsOpenUpdateDialog}
        searchParameter={"firstName"}
      />
      {/* Create Form */}
      <Dialog
        fullWidth
        scroll="paper"
        maxWidth="sm"
        open={isOpenCreateDialog}
        onClose={() => setIsOpenCreateDialog(false)}
        aria-labelledby="dialog-create-user"
        aria-describedby="dialog-create-user-description"
      >
        <DialogTitle id="dialog-create-user">{`Crear usuario`}</DialogTitle>
        <DialogContent>
          <CreationUserForm
            roles={roles}
            onCreateUser={onCreateUser}
            isLoading={props.isLoading}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsOpenCreateDialog(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Dialog */}
      {user && (
        <Dialog
          fullWidth
          scroll="paper"
          maxWidth="sm"
          open={isOpenDeleteDialog}
          onClose={() => setIsOpenDeleteDialog(false)}
          aria-labelledby="dialog-delete-user"
          aria-describedby="dialog-delete-user-description"
        >
          <DialogTitle id="dialog-delete-user">{`Eliminar usuario`}</DialogTitle>
          <DialogContent>
            <DialogContentText>{`¿Está seguro que desea eliminar al usuario ${user?.firstName} ${user?.lastName}?`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={props.isLoading}
              onClick={() => {
                props.onSelectedUser(undefined);
                setIsOpenDeleteDialog(false);
              }}
            >
              Cancelar
            </Button>
            <Button onClick={() => onDeleteUser()} disabled={props.isLoading}>
              {props.isLoading ? <CircularProgress /> : "Si, eliminar"}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Edit Dialog*/}
      {user && (
        <Dialog
          fullWidth
          scroll="paper"
          maxWidth="sm"
          open={isOpenUpdateDualog}
          onClose={() => {
            props.onSelectedUser(undefined);
            setIsOpenUpdateDialog(false);
          }}
          aria-labelledby="dialog-edit-user"
          aria-describedby="dialog-edit-user-description"
        >
          <DialogTitle id="dialog-edit-user">{`Editar usuario`}</DialogTitle>
          <DialogContent>
            <UpdateUserForm
              onEditUser={onUpdateUser}
              user={user}
              isLoading={props.isLoading}
              roles={roles}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsOpenUpdateDialog(false)}>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );

  function openCreateDialog() {
    setIsOpenCreateDialog(true);
  }

  function handleChangePage(event, newPage) {
    props.history.push(`?qpage=${newPage}`);
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  async function onCreateUser(body) {
    await props.onCreateUser(body);
    await props.getAllUsers();
    setIsOpenCreateDialog(false);
  }

  async function onDeleteUser() {
    await props.onDeleteUser(user.id);
    await props.onSelectedUser(undefined);
    await props.getAllUsers();
  }

  async function onUpdateUser(body) {
    await props.onUpdateUser(body);
    await props.onSelectedUser(undefined);
    await props.getAllUsers();
    setIsOpenUpdateDialog(false);
  }
}

export default Users;
