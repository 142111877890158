import { makeStyles } from "@material-ui/styles";
import { Paper, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "12px",
  },
  itemsContainer: {
    marginTop: "16px",
    textAlign: "center",
  },
  content: {
    textDecoration: "none",
  },
  paper: {
    height: "200px",
    padding: "8px",
    borderRadius: "18px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  title: {
    textTransform: "uppercase",
    fontWeight: 600,
    textDecoration: "none",
  },
  qty: {
    marginTop: "32px",
    textDecoration: "none",
  },
}));

function Dashboard(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h4">Dashboard</Typography>
      </div>
      <Grid container spacing={2} className={classes.itemsContainer}>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          xl={3}
          className={classes.comtemt}
        >
          <Paper
            elevation={5}
            className={classes.paper}
            onClick={() => props.setPage(1)}
          >
            <Typography variant="p" className={classes.title}>
              Tiendas
            </Typography>
            <Typography
              variant="h2"
              className={classes.qty}
              style={{ color: "#007853" }}
            >
              {props.state.stores.length}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
          <Paper
            elevation={5}
            className={classes.paper}
            onClick={() => props.setPage(2)}
          >
            <Typography variant="p" className={classes.title}>
              Productos
            </Typography>
            <Typography
              variant="h2"
              className={classes.qty}
              style={{ color: "#00953B" }}
            >
              {props.state.inventory.count}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
          <Paper
            elevation={5}
            className={classes.paper}
            onClick={() => props.setPage(3)}
          >
            <Typography variant="p" className={classes.title}>
              Marketplace
            </Typography>
            <Typography
              variant="h2"
              className={classes.qty}
              style={{ color: "#76BC21" }}
            >
              {props.state.marketplaces.length}
            </Typography>
          </Paper>
        </Grid>
        {props.state.currentUser.roleId !== 3 && (
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <Paper
              elevation={5}
              className={classes.paper}
              onClick={() => props.setPage(4)}
            >
              <Typography variant="p" className={classes.title}>
                Usuarios
              </Typography>
              <Typography
                variant="h2"
                className={classes.qty}
                style={{ color: "#FFA400" }}
              >
                {props.state.users.length}
              </Typography>
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Dashboard;
