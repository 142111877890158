import axiosInstance from "../../api/index";

async function onSignIn({ email, password }) {
  return await axiosInstance.post(`auth/logIn`, { email, password });
}

async function onForwotPassword({ email }) {
  axiosInstance.put(`auth/forgot-password`, { email });
}
const service = {
  onSignIn,
  onForwotPassword,
};

export default service;
