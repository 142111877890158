import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Pagination,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
  TablePagination,  
} from "@material-ui/core";


import { Link } from "react-router-dom";
import ProductDetails from "../../components/productDetails";
import TableComponent from "../../components/table";
import ExportExcel from "../../components/excelExport";
import Loader from "../../components/loader";
import service from "./service";

import {
  ArrowBack as ArrowBackIcon,
  Search,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "12px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  itemsContainer: {
    marginTop: "16px",
    textAlign: "center",
  },
  content: {
    textDecoration: "none",
  },
  paper: {
    height: "200px",
    padding: "8px",
    borderRadius: "18px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  paper_product: {
    marginRight: "12px",
    marginBottom: "12px",
    padding: "12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  product_row:{
    "&:hover": {
      cursor: "pointer",
    }
  },
  title: {
    textTransform: "uppercase",
    fontWeight: 600,
    textDecoration: "none",
  },
  qty: {
    marginTop: "32px",
    textDecoration: "none",
  },
  refreshBtn: {
    marginLeft: "auto !important",
    marginRight: "12px",
  },
  gridContainer: {
    marginTop: "24px",
  },
  skuTitle: {
    fontWeight: "600",
  },
  pagination: {
    textAlign: "center",
    display: "flex",
    position: "sticky",
    bottom: "0",
    padding: "12px", 
    backgroundImage: "linear-gradient(#ffffff0f, #ffffffcf,#ffffff,#ffffff)"
  },
  description: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  search: {
    marginTop: "24px",
    marginLeft: "24px"
  },
}));

function Report(props) {
  const classes = useStyles();
  const [isMainReport, setIsMainReport] = useState(true);
  const [isStoreReport, setIsStoreReport] = useState(false);
  const [isProductReport, setIsProductReport] = useState(false);
  const [isProductDetail, setIsProductDetail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { productsActive } = props.state;
  const {allProductsReport} = props.state;
  const [productsToExport, setProductToExport]  = useState(allProductsReport);
  const [product, setProduct] = useState(undefined);
  const [search, setSearch] = useState(props.startFilter);
  const [storeName, setStoreName] = useState("");
  const [filter, setFilter] = useState({
    page: 0,
    search: "",
    size: 100,
    market: "",
    storeFilter: "",
  })

  const[order,setOrder] = useState("asc")
  const[orderBy,setOrderBy] = useState("sku")
  
  const [storeId, setStoreId] = useState(props.selectedStore)

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [pageStore, setPageStore] = useState(0);
  const { stores, store } = props.state;
  const VTEXStores = props.state.marketplaces.filter(market => {return market.name == "VTEX"})[0].marketplaceStore
  

  const columns = [
    { id: "sku", label: "SKU", minWidth: 100, align: "center" },
    { id: "description", label: "Descripcion", minWidth: 100, align: "center" },
    {
      id: "address",
      label: "Direccion",
      minWidth: 100,
      align: "center",
    },
    {
      id: "active",
      label: "Estado",
      minWidth: 100,
      align: "center",
    },
  ];

  useEffect(async () => {
    await props.getProductsReport(0,"",storeId)
    // await props.getProductsReport(0,"",storeId, orderBy, order, true)
  },[""])

  return !props.isLoading ? (isMainReport ? (
    <div className={classes.root}>
      <div>
        <Typography variant="h4">Reportes</Typography>
      </div>
      <Grid container spacing={4} className={classes.itemsContainer}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}></Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={4} 
          xl={3} 
          className={classes.comtemt}
        >
          <Paper
            elevation={5}
            className={classes.paper}
            onClick={() => toStoreReport()}
          >
            <Typography variant="p" className={classes.title}>
              Tiendas VTEX
            </Typography>
            <Typography
              variant="h2"
              className={classes.qty}
              style={{ color: "#007853" }}
            >
              {VTEXStores.length}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
          <Paper
            elevation={5}
            className={classes.paper}
            onClick={() => toProductReport()}
          >
            <Typography variant="p" className={classes.title}>
              Productos
            </Typography>
            <Typography
              variant="h2"
              className={classes.qty}
              style={{ color: "#00953B" }}
            >
              {props.state.productsActive.count}
            </Typography>
          </Paper>
        </Grid>
        
      </Grid>
    </div>
  ) :  isProductReport ?  // *** REPORTE POR PRODUCTOS *** //

      !isProductDetail ? (
        <div className={classes.root}>
        <div className={classes.titleContainer}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Volver">
              <IconButton
                color="primary"
                onClick={() => {
                  // props.onSelectedProduct(undefined);
                  isStoreReport ? toStoreReport() : toMainReport();
                }}
              >
                <ArrowBackIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Typography variant="h5">{`Productos de ${ isStoreReport ? storeName : 'VTEX'}`}</Typography>
          </div>
          <div className={classes.refreshBtn}>
            <ExportExcel 
              search={search}
              storeId={storeId}
              orderBy={orderBy}
              order={order}
              currData={productsToExport}
              excelData={props.state} 
              // excelData={buildReportExcelData(allProductsReport)} 
              fileName={getNameExcelReport(storeName)} 
              isFullReport={true}
              handleExportReport={props.getProductsReport}
              // handleExportReport={handleExportReport}
               />
          </div>
        </div>

        <Paper
          style={{
            marginTop: "24px",
            borderRadius: "24px",
          }}
          elevation={5}
        >
          <TextField
            id="input-with-icon-textfield"
            className={classes.search}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            onKeyPress={handleKeyPress}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {search !== "" && (
                    <Button
                      style={{ marginRight: "8px", marginBottom: "8px" }}
                      variant="contained"
                      onClick={() => handleFilterData(search)}
                    >
                      Buscar
                    </Button>
                  )}
  
                  {search !== "" && (
                    <Button
                      style={{ marginRight: "8px", marginBottom: "8px" }}
                      variant="outlined"
                      onClick={() => handleClearFilterData()}
                    >
                      Limpiar
                    </Button>
                  )}
                </>
              ),
            }}
            variant="standard"
            placeholder="Buscar..."
          />
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell key={"sku"} align={"center"}>
                    <TableSortLabel
                      active={orderBy === "sku"}
                      direction={orderBy === "sku" ? order : "asc"}
                      onClick={() => createSortHandler("sku")}
                    >
                      SKU
                    </TableSortLabel>
                  </TableCell>
                  <TableCell key={"description"} align={"center"}>
                    <TableSortLabel
                      active={orderBy === "description"}
                      direction={orderBy === "description" ? order : "asc"}
                      onClick={() => createSortHandler("description")}
                    >
                      Nombre
                    </TableSortLabel>
                  </TableCell>
                  <TableCell key={"eiPartnerCode"} align={"center"}>
                    <TableSortLabel
                      active={orderBy === "eiPartnerCode"}
                      direction={orderBy === "eiPartnerCode" ? order : "asc"}
                      onClick={() => createSortHandler("eiPartnerCode")}
                    >
                      EAN
                    </TableSortLabel>
                  </TableCell>
                  <TableCell key={"stock"} align={"center"}>
                    
                      Cantidad
                    
                  </TableCell>
                  <TableCell key={"basePrice"} align={"center"}>
                    
                      Precio Base
                    
                  </TableCell>
                  <TableCell key={"tax"} align={"center"}>
                    
                      Impuesto
                    
                  </TableCell>
                  <TableCell key={"totalPrice"} align={"center"}>
                    
                      Precio Total
                    
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productsActive.rows.map((prod) => {
                  var idx = 0; 
                  if(prod.Stores.length > 1 && parseInt(prod.Stores[0].ProductStores.basePrice) == 0){idx = 1};
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={prod.id} className={classes.product_row}
                      onClick={() => {
                        setProduct(prod);
                        toProductDetail();
                      }}>
                      <TableCell key={"sku"} align={"center"}>
                        <Typography
                          variant="p"
                          className={""}
                        >
                          {parseInt(prod.sku)}
                        </Typography>
                      </TableCell>
                      <TableCell key={"description"} align={"center"}>
                        <Typography
                          variant="p"
                          className={""}
                        >
                          {prod.description}
                        </Typography>
                      </TableCell>
                      <TableCell key={"eiPartnerCode"} align={"center"}>
                        <Typography
                          variant="p"
                          className={""}
                        >
                          {prod.eiPartnerCode}
                        </Typography>
                      </TableCell>
                      <TableCell key={"stock"} align={"center"}>
                        <Typography
                          variant="p"
                          className={""}
                        >
                          {prod.Stores[idx].ProductStores.stock}
                        </Typography>
                      </TableCell>
                      <TableCell key={"basePrice"} align={"center"}>
                        <Typography
                          variant="p"
                          className={""}
                        >
                          {prod.Stores[idx].ProductStores.basePrice}
                        </Typography>
                      </TableCell>
                      <TableCell key={"tax"} align={"center"}>
                        <Typography
                          variant="p"
                          className={""}
                        >
                          {prod.Stores[idx].ProductStores.tax}
                        </Typography>
                      </TableCell>
                      <TableCell key={"totalPrice"} align={"center"}>
                        <Typography
                          variant="p"
                          className={""}
                        >
                          {(parseFloat(prod.Stores[idx].ProductStores.basePrice)+parseFloat(prod.Stores[idx].ProductStores.tax)).toFixed(2)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* <TextField
          fullWidth
          id="input-with-icon-textfield"
          className={classes.search}
          value={search}
          onChange={(event) => {setSearch(event.target.value); /*handleFilterData(search)* /}}
          onKeyPress={handleKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {search !== "" && (
                  <Button
                    style={{ marginRight: "8px", marginBottom: "8px" }}
                    variant="contained"
                    onClick={() => handleFilterData(search)}
                  >
                    Buscar
                  </Button>
                )}

                {search !== "" && (
                  <Button
                    style={{ marginRight: "8px", marginBottom: "8px" }}
                    variant="outlined"
                    onClick={() => handleClearFilterData()}
                  >
                    Limpiar
                  </Button>
                )}
              </>
            ),
          }}
          variant="standard"
          placeholder="Buscar..."
        /> */}
        

        {/* <Grid container className={classes.gridContainer}>
          {productsActive.rows
            // .filter((item) =>
            //   item.description.toLowerCase().includes(search.toLowerCase())
            // )
            .map((item, index) => (
              <Grid item sm={12} md={6} lg={4} xl={3} key={index}>
                <Paper
                  elevation={5}
                  className={classes.paper_product}
                  onClick={() => {
                    setProduct(item);
                    toProductDetail();
                  }}
                >
                  <img src={"/static/placeholder.png"} alt="product" />
                  <div>
                    <div className={classes.skuTitle}>{`SKU: ${parseInt(
                      item.sku
                    )}`}</div>
                    <div className={classes.description}>
                      {item.description}
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
        </Grid> */}
        <div className={classes.pagination} >
          <Pagination
            page={page}
            count={productsActive.pages}
            color="primary"
            sx={{ margin: `auto` }}
            onChange={handleChangeProductPage}
          />
        </div>
        
      </div>
      ) : ( // *** DETALLE DE PRODUCTO *** //
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Volver">
              <IconButton
                color="primary"
                onClick={() => {
                  // props.onSelectedProduct(undefined);
                  toProductReport();
                }}
              >
                <ArrowBackIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Typography variant="h5">{`Producto: ${parseInt(
              product?.sku
            )}`}</Typography>
          </div>
          <div className={classes.refreshBtn}>
            <ExportExcel excelData={buildProductExcelData(product)} fileName={buildProductExcelName(product)} />
          </div>
        </div>
        <ProductDetails product={product} />

      </div>
      )
    : ( // *** REPORTE POR TIENDAS *** //
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Volver">
            <IconButton
              color="primary"
              onClick={() => {
                // props.onSelectedProduct(undefined);
                toMainReport();
              }}
            >
              <ArrowBackIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Typography variant="h4">Tiendas VTEX</Typography>
        </div>
      </div>
      <TableComponent
        {...props}
        // props.state.marketplaces.filter(market => {return market.name == "VTEX"})[0].marketplaceStore
        data={stores.filter( item => {
          return VTEXStores.find(vstore => vstore.storeId == item.id)
        })}
        columns={columns}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isStoreReport={setIsProductReport}
        setStoreName={setStoreName}
        rowsPerPage={rowsPerPage}
        page={pageStore}
        onSelected={setStoreFilter}
        // setIsOpenDeleteDialog={setIsOpenDeleteDialog}
        searchParameter={"description"}
        // onActiveStore={onActiveStore}
      />
      
    </div>
  )): (
    <Loader />
  );

  async function toStoreReport(){
    setIsMainReport(false);
    setIsStoreReport(true);
    setIsProductReport(false);
  }
  
  async function toProductReport(){
    setIsMainReport(false);
    setIsProductReport(true);
    setIsProductDetail(false);
  }

  async function toProductDetail(){
    setIsProductDetail(true);
  }

  async function toMainReport(){
    setStoreId("");
    await props.getProductsReport(1,"","")
    setIsMainReport(true);
    setIsStoreReport(false);
    setIsProductReport(false);
  }

  function handleChangePage(event, newPage) {
    // props.history.push(`?qpage=${newPage}&rows=${rowsPerPage}`);
    setPageStore(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  async function handleFilterData(search) {
    await props.getProductsReport(0, search, storeId, orderBy, order);
    //await props.getProductsReport(0, search, storeId, orderBy, order, true);
  }

  async function handleExportReport() {
    await props.getProductsReport(0, search, storeId, orderBy, order, true);

  }

  async function handleChangeProductPage(event, newPage) {
    setPage(newPage);
    await props.getProductsReport(newPage - 1,search,storeId, orderBy, order);
  }

  async function handleClearFilterData(search) {
    console.log("StoreID: " + storeId);
    await props.getProductsReport(0,"",storeId, orderBy, order);
    //await props.getProductsReport(0, "", storeId, orderBy, order,true);
    setSearch("");
    setPage(1);
  }

  async function setStoreFilter(store, search){
    console.log(store);
    setStoreId(store.id);
    setSearch(search)
    await props.getProductsReport(0, search, store.id, orderBy, order);
    //await props.getProductsReport(0, search, store.id, orderBy, order, true);
  }

  function handleKeyPress(event) {
    if(event.key === 'Enter'){
      handleFilterData(search);
    }
  }

  async function createSortHandler(column){
    let direction = "asc";
    if (orderBy === column) {
      direction = order === 'asc' ? 'desc' : 'asc';
      setOrder(direction)
    } else {
      setOrder(direction);
      setOrderBy(column);
    }
    setPage(1);
    await props.getProductsReport(0, search, storeId, column, direction);
  };

  function buildProductExcelData(product){
    return product.Stores?.map((store) => ({
      "Tienda":store.description,
      "Cantidad":store.ProductStores.stock,
      "Precio base Bs":store.ProductStores.basePrice,
      "Monto impuesto Bs":store.ProductStores.tax,
      "Porcentaje impuesto Bs":`${store.ProductStores.rateTax}%`,
      "Precio base $":store.ProductStores.internationalPrice,
      "Monto impuesto $":store.ProductStores.internationalTax,
      "Porcentaje impuesto":`${store.ProductStores.internationalRateTax}%`,
    }));
  }

  function buildProductExcelName(product){
    return `${product.description.replace(/[^a-z0-9]/gi, '_')}-${parseInt(product?.sku)}`
  }

  function getNameExcelReport(storeName){
    return storeName ? `Productos de ${storeName}` : `Productos de VTEX` 
  }

  function buildReportExcelData(products){
    
    return products?.rows?.map((product) => { 
      var idx = 0; 
      if(product.Stores.length > 1 && parseInt(product.Stores[0].ProductStores.basePrice) == 0){idx = 1};
      return ({
      "SKU":`${parseInt(product.sku)}`,
      "Nombre":product.description,
      "EAN":product.eiPartnerCode,
      "Cantidad":product.Stores[idx].ProductStores.stock,
      "Precio Total Bs":`${(parseFloat(product.Stores[idx].ProductStores.basePrice)+parseFloat(product.Stores[idx].ProductStores.tax)).toFixed(2)}`,
      "Precio Base Bs":product.Stores[idx].ProductStores.basePrice,
      "Impuesto Bs":product.Stores[idx].ProductStores.tax,
      "% impuesto Bs":`${product.Stores[idx].ProductStores.rateTax}%`,
    })})
    
  }

}

export default Report;
