import React, { createRef, useState } from "react";
import { CSVReader } from "react-papaparse";
// Material
import {
  Stack,
  Button,
  Typography,
  Divider,
  CircularProgress,
} from "@material-ui/core";

export default function UploadCSV({
  onUpload = () => {},
  onSubmit = () => {},
  isLoading = false,
}) {
  const csvReaderRef = createRef();
  const [data, setData] = useState([]);

  const handleOnDrop = (result) => {
    const mapResult = result.map(
      ({
        data: {
          SKU,
        },
      }) => ({
        sku: Number(SKU)?.toString(),
      })
    );
    setData(mapResult);
    onUpload(mapResult);
  };
  const handleOnError = (e) => {
    // eslint-disable-next-line no-console
    console.log(e);
  };
  const handleOnRemoveFile = () => {
    setData([]);
    onUpload([]);
  };
  const handleRemoveFile = (e) => {
    if (csvReaderRef.current) {
      csvReaderRef.current.removeFile(e);
    }
  };

  const handleOnClick = (e) => {
    if (csvReaderRef.current) {
      csvReaderRef.current.open(e);
    }
  };

  return (
    <>
      <CSVReader
        ref={csvReaderRef}
        onFileLoad={handleOnDrop}
        onError={handleOnError}
        noClick
        config={{
          header: true,
        }}
        noDrag
        onRemoveFile={handleOnRemoveFile}
      >
        {({ file }) => (
          <Stack>
            <Stack mb={2}>
              <Typography mb={1}>
                Para configurar mediante archivo CSV debe respetar el formato
                del template que se adjunta a continuación:
              </Typography>
              <a href="/static/file/templateDiscountProducts.csv">Descargar template</a>
            </Stack>
            <Stack>
              <Typography variant="h6" textAlign="center" color="primary">
                {file && file.name}
              </Typography>
            </Stack>
          </Stack>
        )}
      </CSVReader>
      <Stack justifyContent="center" flexDirection="row">
        <Stack
          flexDirection="row"
          width="50%"
          justifyContent="space-around"
          mt={3}
          mb={1}
        >
          <Button
            onClick={handleOnClick}
            variant="contained"
            color="secondary"
            size="small"
          >
            Examinar
          </Button>
          <Button onClick={handleRemoveFile} size="small" color="secondary">
            Restablecer
          </Button>
        </Stack>
      </Stack>
      <Stack mt={1}>
        <Divider />
      </Stack>
      <Stack mt={2}>
        <Button
          variant="contained"
          //disabled={!data.length}
          onClick={onSubmit}
          disabled={data.length === 0}
        >
          {isLoading ? <CircularProgress /> : "Cargar archivo"}
        </Button>
      </Stack>
    </>
  );
}
