import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: "flex",
    alignItems: "center",
  },
  image: {
    width: "350px",
  },
  infoItem: {
    display: "flex",
    alignItems: "center",
  },
  titleInfoItem: {
    width: "150px",
    marginBottom: "8px",
  },
  titleText: {
    color: "#00AB55",
    fontWeight: "600",
  },
  containerText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  infoText: {},
}));

function ProductDetails({ product }) {
  const classes = useStyles();
  return product ? (
    <Grid container>
      <Grid item sm={12} md={12} lg={12} xl={12}>
        <div className={classes.infoContainer}>
          <img
            className={classes.image}
            src={"/static/placeholder.png"}
            alt="product?"
          />

          <div>
            <div className={classes.infoItem}>
              <div className={classes.titleInfoItem}>
                <Typography variant="p" className={classes.titleText}>
                  SKU
                </Typography>
              </div>
              <div className={classes.infoText}>
                <Typography variant="p">{product?.sku}</Typography>
              </div>
            </div>
            <div className={classes.infoItem}>
              <div className={classes.titleInfoItem}>
                <Typography variant="p" className={classes.titleText}>
                  Descripción
                </Typography>
              </div>
              <div className={classes.infoText}>
                <Typography variant="p">{product?.description}</Typography>
              </div>
            </div>
            <div className={classes.infoItem}>
              <div className={classes.titleInfoItem}>
                <Typography variant="p" className={classes.titleText}>
                  Código de barra
                </Typography>
              </div>
              <div className={classes.infoText}>
                <Typography variant="p">{product?.eiPartnerCode}</Typography>
              </div>
            </div>

            <div className={classes.infoItem}>
              <div className={classes.titleInfoItem}>
                <Typography variant="p" className={classes.titleText}>
                  Categoría
                </Typography>
              </div>
              <div className={classes.infoText}>
                <Typography variant="p">{product?.category}</Typography>
              </div>
            </div>

            <div className={classes.infoItem}>
              <div className={classes.titleInfoItem}>
                <Typography variant="p" className={classes.titleText}>
                  Informacion extra
                </Typography>
              </div>
              <div className={classes.infoText}>
                <Typography variant="p">{product?.extraInfo}</Typography>
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item sm={12} md={12} lg={12} xl={12}>
        <TableproductDetails data={product?.Stores || []} />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
}

function TableproductDetails({ data }) {
  console.log(data, "DATA");
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Tienda</TableCell>
            <TableCell align="center">Cantidad</TableCell>
            <TableCell align="center">Precio base Bs</TableCell>
            <TableCell align="center">Monto impuesto Bs</TableCell>

            <TableCell align="center">Porcentaje impuesto Bs</TableCell>
            <TableCell align="center">Precio base $</TableCell>

            <TableCell align="center">Monto impuesto $</TableCell>
            <TableCell align="center">Porcentaje impuesto $</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.sku +" - "+ row.description}
              </TableCell>
              <TableCell align="center">{row.ProductStores.stock}</TableCell>
              <TableCell align="center">
                {row.ProductStores.basePrice}
              </TableCell>
              <TableCell align="center">{row.ProductStores.tax}</TableCell>
              <TableCell align="center">{`${row.ProductStores.rateTax}%`}</TableCell>
              <TableCell align="center">
                {row.ProductStores.internationalPrice}
              </TableCell>
              <TableCell align="center">{`${row.ProductStores.internationalTax}`}</TableCell>
              <TableCell align="center">{`${row.ProductStores.internationalRateTax}%`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProductDetails;
