import { Switch, Route, Redirect } from "react-router-dom";

import Auth from "../pages/Auth/";

export const AuthRouter = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/auth/signin"
          component={(props) => <Auth {...props} />}
        />
        {/* <Route
          exact
          path="/auth/forgot-password"
          component={(props) => <ForgotPassword {...props} />}
        />
        <Route
          exact
          path="/auth/new-password/:token"
          component={(props) => <h1>hola</h1>}
        /> */}
      </Switch>
      <Redirect to="/auth/signin" />
    </>
  );
};
