import React, { useRef, useState } from "react";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
  SettingsApplications as SettingsApplicationsIcon,
  EditAttributes as EditAttributesIcon,
  Download as IconDownload,
} from "@material-ui/icons";
// ----------------------------------------------------------------------

export default function MoreMenuExtend({
  onDelete = () => {},
  onEdit = () => {},
  onConfig = () => {},
  onLoadFile = () => {},
  onDesactive = () => {},
  disabledConfig = false,
  statusConfig = false,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleEdit = () => {
    onEdit();
    setIsOpen(false);
  };

  const handleDelete = () => {
    onDelete();
    setIsOpen(false);
  };

  const handleLoadConfig = () => {
    onLoadFile();
    setIsOpen(false);
  };

  const handleConfig = () => {
    onConfig();
    setIsOpen(false);
  };

  const handleDesactive = () => {
    onDesactive();
    setIsOpen(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)} color="primary">
        <MoreVertIcon color="primary" />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 270, maxWidth: `100%` },
        }}
        anchorOrigin={{ vertical: `top`, horizontal: `right` }}
        transformOrigin={{ vertical: `top`, horizontal: `right` }}
      >
        <MenuItem onClick={handleConfig} sx={{ color: `text.secondary` }}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Configurar"
            primaryTypographyProps={{ variant: `body2` }}
          />
        </MenuItem>
        {/* <MenuItem onClick={handleLoadConfig} sx={{ color: `text.secondary` }}>
          <ListItemIcon>
            <SettingsApplicationsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Cargar Configuración (CSV)"
            primaryTypographyProps={{ variant: `body2` }}
          />
        </MenuItem> */}

        <MenuItem onClick={handleEdit} sx={{ color: `text.secondary` }}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText
            primary="Editar"
            primaryTypographyProps={{ variant: `body2` }}
          />
        </MenuItem>
        <MenuItem onClick={handleDelete} sx={{ color: `text.secondary` }}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText
            primary="Eliminar"
            primaryTypographyProps={{ variant: `body2` }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
