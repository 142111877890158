import React, { useState } from "react";
import { Button, Tooltip } from "@material-ui/core";
import * as FileSaver from "file-saver"
import XLSX from "sheetjs-style"

import {
    Download as DownloadIcon,
  } from "@material-ui/icons";


const ExportExcel = ({search,storeId,orderBy,order, excelData, fileName,handleExportReport, currData}) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;chartset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {

        await handleExportReport(0, search, storeId, orderBy, order, true);

        console.log("excelData:")
        console.log(excelData.allProductsReport);

        const ws = XLSX.utils.json_to_sheet(buildReportExcelData(excelData.allProductsReport));
        const wb = {Sheets : {'data' : ws}, SheetNames : ['data']};
        const excelBuffer = XLSX.write(wb, {bookType:'xlsx',type:'array'});
        const data = new Blob([excelBuffer],{type:fileType});
        FileSaver.saveAs(data,fileName,fileExtension);
    }

    return (
        <Tooltip title="Exportar a Excel">
            <Button variant="contained"
                onClick={(e) => exportToExcel(fileName)}
                color="primary"
                startIcon={<DownloadIcon />}
                style={{cursor:"pointer",fontSize:14}}
            >Descargar
            </Button>
        </Tooltip>
    )
}

function buildReportExcelData(products){
    
    return products?.rows?.map((product) => { 
      var idx = 0; 
      if(product.Stores.length > 1 && parseInt(product.Stores[0].ProductStores.basePrice) == 0){idx = 1};
      return ({
      "SKU":`${parseInt(product.sku)}`,
      "Nombre":product.description,
      "EAN":product.eiPartnerCode,
      "Cantidad":product.Stores[idx].ProductStores.stock,
      "Precio Total Bs":`${(parseFloat(product.Stores[idx].ProductStores.basePrice)+parseFloat(product.Stores[idx].ProductStores.tax)).toFixed(2)}`,
      "Precio Base Bs":product.Stores[idx].ProductStores.basePrice,
      "Impuesto Bs":product.Stores[idx].ProductStores.tax,
      "% impuesto Bs":`${product.Stores[idx].ProductStores.rateTax}%`,
    })})    
}

export default ExportExcel


