import CircularProgress from "@material-ui/core/CircularProgress";

function Loader(props) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        minHeight: "80vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size={75} />
    </div>
  );
}

export default Loader;
