import AppRouter from "./routes/AppRouter";
import ThemeConfig from "./theme";

import { CssBaseline } from "@material-ui/core";

function App() {
  return (
    <ThemeConfig>
      <CssBaseline />
      <AppRouter />
    </ThemeConfig>
  );
}

export default App;
