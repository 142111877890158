import { useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  MenuItem,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

function CreationUserForm(props) {
  const [showPassword, setShowPassword] = useState(false);

  const CreateUserSchema = Yup.object().shape({
    firstName: Yup.string().required(`El nombre es requerido`),
    lastName: Yup.string().required(`El apellido es requerido`),
    email: Yup.string()
      .email(
        `El correo electrónico debe ser una dirección de correo electrónico válida`
      )
      .required(`El correo electrónico es requerido`),
    role: Yup.string().required(`El rol es requerido`),
    password: Yup.string()
      .required(`La contraseña es requerida`)
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
        {
          excludeEmptyString: false,
          message: `La contraseña no cumple con el patron indicado`,
        }
      ),
    confirmPassword: Yup.string()
      .required(`La confirmación de contraseña es requerida`)
      .oneOf([Yup.ref(`password`), null], `Las contraseñas deben coincidir`),
  });

  const formik = useFormik({
    initialValues: {
      firstName: ``,
      lastName: ``,
      email: ``,
      role: ``,
      password: ``,
      confirmPassword: ``,
    },
    validationSchema: CreateUserSchema,
    onSubmit: ({ firstName, lastName, email, role, password }) => {
      props.onCreateUser({
        firstName,
        lastName,
        email,
        roleId: role,
        password,
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} pt={2}>
          <TextField
            fullWidth
            type="text"
            label="Nombre"
            {...getFieldProps(`firstName`)}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
          />
          <TextField
            fullWidth
            type="text"
            label="Apellido"
            {...getFieldProps(`lastName`)}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />
          <TextField
            fullWidth
            type="email"
            label="Correo electrónico"
            {...getFieldProps(`email`)}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            id="outlined-select-role"
            select
            label="Rol"
            {...getFieldProps(`role`)}
            error={Boolean(touched.role && errors.role)}
            helperText={touched.role && errors.role}
          >
            {props.roles.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            type={showPassword ? `text` : `password`}
            label="Contraseña"
            {...getFieldProps(`password`)}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            type={showPassword ? `text` : `password`}
            label="Confirmar contraseña"
            {...getFieldProps(`confirmPassword`)}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        {/* <Stack sx={{ my: 2 }}>
        <Typography variant="body1" sx={{ color: `text.secondary` }}>
          La contraseña debe incluir:
        </Typography>
        <List dense={false}>
          <ListItem sx={{ py: 0 }}>
            <ListItemText secondary="Al menos una letra mayúscula" />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText secondary="Al menos una letra minúscula" />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText secondary="Al menos un número" />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText secondary="Al menos un caracter especial (#?!@$%^&*-)" />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText secondary="Minimo 6 caracteres" />
          </ListItem>
        </List>
      </Stack> */}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={props.isLoading || Object.keys(errors).length > 0}
          >
            {props.isLoading ? <CircularProgress /> : "Crear"}
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default CreationUserForm;
