import { useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { makeStyles } from "@material-ui/styles";
import {
  Stack,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

function UpdateDiscountForm({ currDiscount, onUpdateDiscount }) {
  const [isChecked, setIsChecked] = useState(
    currDiscount.active ? true : false
  );

  const [startDate, setStartDate] = useState(new Date(currDiscount.startDate));
  const [endDate, setEndDate] = useState(new Date(currDiscount.endDate));

  const CreateDiscountSchema = Yup.object().shape({
    name: Yup.string().required(`El nombre es requerido`),
    description: Yup.string(),
    discount: Yup.number().required(
      `El monto de descuento es requerido`
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: currDiscount.name,
      description: currDiscount.description ?? ``,
      discount: currDiscount.discount,
      startDate: startDate,
      endDate: endDate,
      active: currDiscount.active,
    },
    validationSchema: CreateDiscountSchema,
    onSubmit: ({
        name,
        description,
        discount,
        active,
    }) => {
      onUpdateDiscount({
        id: currDiscount.id,
        name,
        description,
        discount,
        startDate,
        endDate,
        active: isChecked,
      });
    },
  });

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const DateInput = ({value, label, onClick}) => (
    <input type="text" value={value} onClick={onClick} className="dateInputClass" placeholder={label}/>
  )

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} pt={2}>
          <FormControlLabel
            control={<Switch checked={isChecked} onChange={handleChange} />}
            label="Activo"
          />
          <TextField
            fullWidth
            type="text"
            label="Nombre"
            {...getFieldProps(`name`)}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            fullWidth
            type="text"
            label="Descripción"
            {...getFieldProps(`description`)}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          />
          <TextField
            fullWidth
            type="text"
            label="Descuento (%)"
            {...getFieldProps(`discount`)}
            error={Boolean(
              touched.discount && errors.discount
            )}
            helperText={touched.discount && errors.discount}
          />
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
            isClearable
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate}
            showIcon
            locale={es}
            customInput={<DateInput label={"Válido desde"} />}
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="yyyy-MM-dd"
            isClearable
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            showIcon
            locale={es}
            customInput={<DateInput label={"Válido hasta"} />}
          />
          <TextField
            fullWidth
            type="text"
            className="hiddenInput"
            label="Válido desde"
            {...getFieldProps(`startDate`)}
            error={Boolean(
              touched.startDate && errors.startDate
            )}
            helperText={touched.startDate && errors.startDate}
          />
          <TextField
            fullWidth
            type="text"
            className="hiddenInput"
            label="Válido hasta"
            {...getFieldProps(`endDate`)}
            error={Boolean(touched.endDate && errors.endDate)}
            helperText={touched.endDate && errors.endDate}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          <Button fullWidth size="large" type="submit" variant="contained">
            Editar
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default UpdateDiscountForm;
