import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Stack,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
} from "@material-ui/core";

function UpdateUserForm(props) {
  const EditUserSchema = Yup.object().shape({
    firstName: Yup.string().required(`El nombre es requerido`),
    lastName: Yup.string().required(`El apellido es requerido`),
    email: Yup.string()
      .email(
        `El correo electrónico debe ser una dirección de correo electrónico válida`
      )
      .required(`El correo electrónico es requerido`),
    role: Yup.string().required(`El rol es requerido`),
  });

  const formik = useFormik({
    initialValues: {
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      email: props.user.email,
      role: props.roles.find(
        (role) => role.description === props.user.role.description
      ).id,
    },
    validationSchema: EditUserSchema,
    onSubmit: ({ firstName, lastName, email, role }) => {
      props.onEditUser({
        id: props.user.id,
        firstName,
        lastName,
        email,
        roleId: role,
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} pt={2}>
          <TextField
            fullWidth
            type="text"
            label="Nombre"
            {...getFieldProps(`firstName`)}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
          />
          <TextField
            fullWidth
            type="text"
            label="Apellido"
            {...getFieldProps(`lastName`)}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />
          <TextField
            fullWidth
            type="email"
            label="Correo electrónico"
            {...getFieldProps(`email`)}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            id="outlined-select-role"
            select
            label="Rol"
            {...getFieldProps(`role`)}
            error={Boolean(touched.role && errors.role)}
            helperText={touched.role && errors.role}
          >
            {props.roles.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.description}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={props.isLoading || Object.keys(errors).length > 0}
          >
            {props.isLoading ? <CircularProgress /> : "Editar"}
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default UpdateUserForm;
