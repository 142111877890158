import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import DateObject from "react-date-object";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

import CreateDiscountForm from "../../components/createDiscountForm";
import UpdateDiscountForm from "../../components/updateDiscountForm";
import UpdateDiscountProducts from "../../components/updateDiscountProducts"


import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Grid,
  Pagination,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
  TablePagination,  
  CircularProgress,
} from "@material-ui/core";


import TableComponent from "../../components/table";
import Loader from "../../components/loader";
import service from "./service";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import StoreIcon from "@material-ui/icons/Store";


import {
  ArrowBack as ArrowBackIcon,
  Search,
} from "@material-ui/icons";
import zIndex from "@material-ui/core/styles/zIndex";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "12px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  itemsContainer: {
    marginTop: "16px",
    textAlign: "center",
  },
  content: {
    textDecoration: "none",
  },
  paper: {
    height: "200px",
    padding: "8px",
    borderRadius: "18px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  paper_product: {
    marginRight: "12px",
    marginBottom: "12px",
    padding: "12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  product_row:{
    "&:hover": {
      cursor: "pointer",
    }
  },
  title: {
    textTransform: "uppercase",
    fontWeight: 600,
    textDecoration: "none",
  },
  qty: {
    marginTop: "32px",
    textDecoration: "none",
  },
  refreshBtn: {
    marginLeft: "auto !important",
    marginRight: "12px",
  },
  gridContainer: {
    marginTop: "24px",
  },
  skuTitle: {
    fontWeight: "600",
  },
  pagination: {
    textAlign: "center",
    display: "flex",
    position: "sticky",
    bottom: "0",
    padding: "12px", 
    backgroundImage: "linear-gradient(#ffffff0f, #ffffffcf,#ffffff,#ffffff)"
  },
  description: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  search: {
    marginTop: "24px",
    marginLeft: "24px"
  },
  discountDescription:{
    maxWidth:"300px",
    minWidth:"300px"
  },
  datePickerContainer:{
    display: "inline-block",
    marginTop: "24px",
    position: "relative",
    float: "right",
    zIndex:1000
  },
  datePickerInputDiv:{
    display: "inline-block",
    marginRight: "24px",
  },
  text: {
    width: "50px",
    background: "#fffff",
    borderRadius: "20px",
    color: "#00AB55",
    border: "solid 1px #00AB55",
    fontWeight: "600",
    fontSize: "12px",
    padding: "5px",
  },
  textInactive: {
    width: "50px",
    background: "#fffff",
    borderRadius: "20px",
    color: "red",
    border: "solid 1px red",
    fontWeight: "600",
    fontSize: "12px",
    padding: "5px",
  },
}));

function Discount(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenUpdateDialog, setIsOpenUpdateDialog] = useState(false);
  const [search, setSearch] = useState(props.startFilter);
  const { listDiscounts, currDiscount } = props.state;
  const [isDiscountSelected, setIsDiscountSelected] = useState(false);
  const [filter, setFilter] = useState({
    page: 0,
    search: "",
    size: 100,
  })


  const[order,setOrder] = useState("asc")
  const[orderBy,setOrderBy] = useState("updatedAt")
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(1);

  const columns = [
    { id: "updatedAt", label: "Fecha", minWidth: 100, align: "center" },
    { id: "module", label: "Módulo", minWidth: 100, align: "center" },
    { id: "action", label: "Acción", minWidth: 100, align: "center" },
    {
      id: "user",
      label: "Usuario",
      minWidth: 100,
      align: "center",
    },
    {
      id: "description",
      label: "Descripción",
      minWidth: 100,
      maxWidth:200,
      align: "center",
    },
  ];

  useEffect(async () => {
    await props.getAllDiscounts(0,20,"")
    console.log("DONE!!!")
  },[""])

  useEffect(async () => {

    console.log("Cambie...")
    console.log("StarDate: " + startDate)
    console.log("EndDate: " + endDate)
    await props.getAllDiscounts(0,20, search, orderBy, order, startDate, endDate);
    
  },[startDate, endDate])

  return !props.isLoading ? (

    !isDiscountSelected ? (
    <div className={classes.root}>
    <div className={classes.titleContainer}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5">{`Descuentos`}</Typography>
      </div>
      <div className={classes.refreshBtn}>
        <Button
            onClick={() => setIsOpenCreateDialog(true)}
            variant="contained"
            startIcon={<AddIcon />}
            disabled={false}
        >
            Agregar Descuento
        </Button>
        </div>
    </div>

    <Paper
      style={{
        marginTop: "24px",
        borderRadius: "24px",
      }}
      elevation={5}
    >
      <TextField
        id="input-with-icon-textfield"
        className={classes.search}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        onKeyPress={handleKeyPress}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {search !== "" && (
                <Button
                  style={{ marginRight: "8px", marginBottom: "8px" }}
                  variant="contained"
                  onClick={() => handleFilterData(search)}
                >
                  Buscar
                </Button>
              )}

              {search !== "" && (
                <Button
                  style={{ marginRight: "8px", marginBottom: "8px" }}
                  variant="outlined"
                  onClick={() => handleClearFilterData()}
                >
                  Limpiar
                </Button>
              )}
            </>
          ),
        }}
        variant="standard"
        placeholder="Buscar..."
      />
      {/*<div className={classes.datePickerContainer}>
        <div className={classes.datePickerInputDiv}>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
          placeholderText="Fecha desde"
          dateFormat="yyyy/MM/dd"
          isClearable
          showIcon
          locale={es}
        />
        </div>
        <div className={classes.datePickerInputDiv}>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="Fecha hasta"
          dateFormat="yyyy/MM/dd"
          isClearable
          showIcon
          locale={es}
        />
        </div>
    </div>*/}
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell key={"updatedAt"} align={"center"}>
                <TableSortLabel
                  active={orderBy === "updatedAt"}
                  direction={orderBy === "updatedAt" ? order : "desc"}
                  onClick={() => createSortHandler("updatedAt")}
                >
                  Actualizado
                </TableSortLabel>
              </TableCell>
              <TableCell key={"createdAt"} align={"center"}>
                <TableSortLabel
                  active={orderBy === "createdAt"}
                  direction={orderBy === "createdAt" ? order : "desc"}
                  onClick={() => createSortHandler("createdAt")}
                >
                  Creado
                </TableSortLabel>
              </TableCell>
              <TableCell key={"name"} align={"center"}>
                <TableSortLabel
                  active={orderBy === "name"}
                  direction={orderBy === "name" ? order : "asc"}
                  onClick={() => createSortHandler("name")}
                >
                  Nombre
                </TableSortLabel>
              </TableCell>
              <TableCell key={"discount"} align={"center"}>
                <TableSortLabel
                  active={orderBy === "discount"}
                  direction={orderBy === "discount" ? order : "asc"}
                  onClick={() => createSortHandler("discount")}
                >
                  Descuento
                </TableSortLabel>
              </TableCell>
              <TableCell key={"user"} align={"center"}>
                <TableSortLabel
                  active={orderBy === "username"}
                  direction={orderBy === "username" ? order : "asc"}
                  onClick={() => createSortHandler("username")}
                >
                  Creado por
                </TableSortLabel>
              </TableCell>
              <TableCell key={"description"} align={"center"}>
                
                  Info adicional
                
              </TableCell>
              <TableCell key={"active"} align={"center"}>
                <TableSortLabel
                  active={orderBy === "active"}
                  direction={orderBy === "active" ? order : "asc"}
                  onClick={() => createSortHandler("active")}
                >
                  Estatus
                </TableSortLabel>
              </TableCell>
              <TableCell key={"actions"} align={"center"}>
                
                  Acciones
                
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listDiscounts.rows?.map((discount) => {
              //var idx = 0; 
              //if(prod.Stores.length > 1 && parseInt(prod.Stores[0].ProductStores.basePrice) == 0){idx = 1};

              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={discount.id} className={classes.product_row}
                  onClick={() => {
                    //setProduct(prod);
                    //toProductDetail();
                  }}>
                  <TableCell key={"updatedAt"} align={"center"}>
                    <Typography
                      variant="p"
                      className={""}
                    >
                      {new DateObject(discount.updatedAt).format("YYYY/MM/DD hh:mm:ss a")}
                    </Typography>
                  </TableCell>
                  <TableCell key={"createdAt"} align={"center"}>
                    <Typography
                      variant="p"
                      className={""}
                    >
                      {new DateObject(discount.createdAt).format("YYYY/MM/DD hh:mm:ss a")}
                    </Typography>
                  </TableCell>
                  <TableCell key={"name"} align={"center"}>
                    <Typography
                      variant="p"
                      className={""}
                    >
                      {discount.name}
                    </Typography>
                  </TableCell>
                  <TableCell key={"action"} align={"center"}>
                    <Typography
                      variant="p"
                      className={""}
                    >
                      {formatPercent(discount.discount)}
                    </Typography>
                  </TableCell>
                  <TableCell key={"user"} align={"center"}>
                    <Typography
                      variant="p"
                      className={""}
                    >
                      {discount.Users.firstName + " "+ discount.Users.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell key={"description"} align={"center"} className={classes.discountDescription}>
                    <Typography
                      variant="p"
                      className={""}
                    >
                      { new String(discount.description? discount.description:"").replaceAll(",", ", ")}
                    </Typography>
                  </TableCell>
                  <TableCell key={"active"} align={"center"} className={""}>
                    <Typography
                      variant="p"
                      className={discount.active ? classes.text : classes.textInactive}
                    >
                      { discount.active? "Activo":"Inactivo" }
                    </Typography>
                  </TableCell>
                  <TableCell key={"actions"} align={"center"} className={""}>
                    <Tooltip title="Ver productos" placement="top">
                        <IconButton
                        color="primary"
                        onClick={async () => {
                            
                            //setStoreName(row.description);
                            props.onSelectedDiscount(discount);
                            setIsDiscountSelected(true);
                            props.getAllProductsDiscount(discount.id)

                            //isDiscountDetail(true);
                        }}
                        >
                        <StoreIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar" placement="top">
                        <IconButton
                        color="primary"
                        onClick={() => {
                            props.onSelectedDiscount(discount);
                            setIsOpenUpdateDialog(true);
                        }}
                        >
                            <EditIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Eliminar"} placement="top">
                        <IconButton
                        color="primary"
                        onClick={() => {
                            props.onSelectedDiscount(discount);
                            setIsOpenDeleteDialog(true);
                        }}
                        >
                        <DeleteIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                  </TableCell>
                  
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>

    {/* Create Dialog */}
    <Dialog
          fullWidth
          scroll="paper"
          maxWidth="sm"
          open={isOpenCreateDialog}
          onClose={() => setIsOpenCreateDialog(false)}
          aria-labelledby="dialog-create-user"
          aria-describedby="dialog-create-user-description"
        >
          <DialogTitle id="dialog-create-user">{`Crear Marketplace`}</DialogTitle>
          <DialogContent>
            <CreateDiscountForm
              onCreateDiscount={onCreateDiscount}
              isLoading={props.isLoading}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={props.isLoading}
              onClick={() => {
                setIsOpenCreateDialog(false);
              }}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete Dialog */}
        {currDiscount && (
          <Dialog
            fullWidth
            scroll="paper"
            maxWidth="sm"
            open={isOpenDeleteDialog}
            onClose={() => setIsOpenDeleteDialog(false)}
            aria-labelledby="dialog-delete-user"
            aria-describedby="dialog-delete-user-description"
          >
            <DialogTitle id="dialog-delete-user">
              Eliminar Descuento
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{`¿Está seguro que desea eliminar el descuento "${currDiscount.name}"?`}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={props.isLoading}
                onClick={() => {
                  props.onSelectedDiscount(undefined);
                  setIsOpenDeleteDialog(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => onDeleteDiscount(currDiscount.id)}
                disabled={props.isLoading}
              >
                {props.isLoading ? <CircularProgress /> : `Sí, eliminar`}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Update Dialog */}
        {currDiscount && (
          <Dialog
            fullWidth
            scroll="paper"
            maxWidth="sm"
            open={isOpenUpdateDialog}
            onClose={() => setIsOpenUpdateDialog(false)}
            aria-labelledby="dialog-delete-user"
            aria-describedby="dialog-delete-user-description"
          >
            <DialogTitle id="dialog-delete-user">
              Editar Descuento
            </DialogTitle>
            <DialogContent>
              <UpdateDiscountForm
                currDiscount={currDiscount}
                onUpdateDiscount={onUpdateDiscount}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={props.isLoading}
                onClick={() => {
                  props.onSelectedDiscount(undefined);
                  setIsOpenUpdateDialog(false);
                }}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        )}

    <div className={classes.pagination} >
      <Pagination
        page={page}
        count={props.state.listDiscounts.pages}
        color="primary"
        sx={{ margin: `auto` }}
        onChange={handleChangeAuditPage}
      />
    </div>
    
  </div>

    ) : (
        <UpdateDiscountProducts
        {...props}
        storeMarketplace={props.storeMarketplace}
        marketplace={props.marketplace}
        store={props.store}
        updateStoreAndMarketplace={props.updateStoreAndMarketplace}
        saveUpdateByStoreAndMarketplace={
          props.saveUpdateByStoreAndMarketplace
        }
        setIsUpdateInventory={props.setIsUpdateInventory}
        isUpdateInventory={props.isUpdateInventory}
        setIsDiscountSelected={setIsDiscountSelected}
      />    
    )
  

  ) : (
    <Loader />
  );

  function handleChangePage(event, newPage) {
    // props.history.push(`?qpage=${newPage}&rows=${rowsPerPage}`);
    //setPageStore(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  async function handleFilterData(search) {
    await props.getAllDiscounts(0,20, search, orderBy, order, startDate, endDate);
  }


  async function handleChangeAuditPage(event, newPage) {
    setPage(newPage);
    await props.getAllDiscounts(newPage - 1,20,search, orderBy, order, startDate, endDate);
  }

  async function handleClearFilterData(search) {
    //console.log("StoreID: " + storeId);
    await props.getAllDiscounts(0,20,"", orderBy, order, startDate, endDate);
    setSearch("");
    setPage(1);
  }

  async function onCreateDiscount(body) {
    console.log(body)
    await props.onCreateDiscount(body);
    await props.getAllDiscounts(0,20,"", orderBy, order, startDate, endDate);
    setIsOpenCreateDialog(false);
  }

  async function onUpdateDiscount(body) {
    console.log(body)
    await props.onUpdateDiscount(body);
    await props.getAllDiscounts(0,20,"", orderBy, order, startDate, endDate);
    setIsOpenUpdateDialog(false);
  }

  async function onDeleteDiscount() {
    await props.onDeleteDiscount(currDiscount.id);
    await props.onSelectedDiscount(undefined);
    await props.getAllDiscounts(0,20,"", orderBy, order, startDate, endDate);
    setIsOpenDeleteDialog(false);
  }


  function handleKeyPress(event) {
    if(event.key === 'Enter'){
      handleFilterData(search);
    }
  }

  function formatPercent(value){
    return parseFloat(value).toFixed(2) + " %";
  }

    async function createSortHandler(column){
    let direction = "asc";
    if (orderBy === column) {
      direction = order === 'asc' ? 'desc' : 'asc';
      setOrder(direction)
    } else {
      setOrder(direction);
      setOrderBy(column);
    }
    setPage(1);
    await props.getAllDiscounts(0,20, search, column, direction, startDate, endDate);
  };

    
}

export default Discount;


