import * as Yup from "yup";
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from "formik";
import { Stack, TextField, CircularProgress, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { makeStyles } from "@material-ui/styles";

function CreateDiscountForm(props) {
  const CreateDiscountSchema = Yup.object().shape({
    name: Yup.string().required(`El nombre es requerido`),
    description: Yup.string(),
    discount: Yup.number().required(
      `El monto de descuento es requerido`
    ),
  });

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const useStyles = makeStyles((theme) => ({
    hiddenInput: {
      display: "none",
    },
  }))

  const formik = useFormik({
    initialValues: {
      name: ``,
      description: ``,
      discount: ``,
      startDate: startDate,
      endDate: endDate,
      active: false,
    },
    validationSchema: CreateDiscountSchema,
    onSubmit: ({
      name,
      description,
      discount,
      active,
    }) => {
      props.onCreateDiscount({
        name,
        description,
        discount,
        startDate,
        endDate,
        active,
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  
  const DateInput = ({value, label, onClick}) => (
    <input type="text" value={value} onClick={onClick} className="dateInputClass" placeholder={label}/>
  )

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} pt={2}>
          <TextField
            fullWidth
            type="text"
            label="Nombre"
            {...getFieldProps(`name`)}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            fullWidth
            type="text"
            label="Descripción"
            {...getFieldProps(`description`)}
            error={Boolean(touched.partnerId && errors.partnerId)}
            helperText={touched.partnerId && errors.partnerId}
          />
          <TextField
            fullWidth
            type="text"
            label="Descuento (%)"
            {...getFieldProps(`discount`)}
            error={Boolean(
              touched.typeAuthentication && errors.typeAuthentication
            )}
            helperText={touched.typeAuthentication && errors.typeAuthentication}
          />
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
            isClearable
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate}
            showIcon
            locale={es}
            customInput={<DateInput label={"Válido desde"} />}
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="yyyy-MM-dd"
            isClearable
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            showIcon
            locale={es}
            customInput={<DateInput label={"Válido hasta"} />}
          />
          <TextField
            fullWidth
            type="text"
            label="Válido desde"
            className="hiddenInput"
            {...getFieldProps(`startDate`)}
            error={Boolean(
              touched.nameAuthentication && errors.nameAuthentication
            )}
            helperText={touched.nameAuthentication && errors.nameAuthentication}
          />
          <TextField
            fullWidth
            type="text"
            label="Válido hasta"
            className="hiddenInput"
            {...getFieldProps(`endDate`)}
            error={Boolean(touched.token && errors.token)}
            helperText={touched.token && errors.token}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          <Button
            fullWidth
            disabled={props.isLoading}
            size="large"
            type="submit"
            variant="contained"
          >
            {props.isLoading ? <CircularProgress /> : "Crear"}
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default CreateDiscountForm;
